/* eslint-disable no-console */
import React, { createContext, useCallback, useMemo, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { MENU_CATEGORY_HASH, SUB_CATEGORIES_HASH } from '../../const/menu'
import { reducer } from './reducer'
import {
  ADD_ITEM_TO_CART,
  CLEAR_CART,
  FETCH_ITEMS_BY_CATEGORY,
  HIDE_LOADER,
  REMOVE_ITEM_FROM_CART,
  SET_CART_FROM_LOCAL_STORAGE,
  SET_ERROR,
  SET_ITEM_AMOUNT_TO_CART,
  SET_ITEM_VARIANTS,
  SET_ITEM_WEIGHT,
  SET_MENU_CATEGORY,
  SET_MENU_SUB_CATEGORY,
  SHOW_LOADER,
} from './types'

export const MenuPageContext = createContext({})

export function MenuPageProvider({ children }) {
  const initialState = {
    menuCategory: MENU_CATEGORY_HASH.PIES,
    subMenuCategory: SUB_CATEGORIES_HASH.STANDARD_PIES,
    menuItems: [],
    cartItems: [],
    loading: false,
    errors: null,
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  const showLoader = () => dispatch({ type: SHOW_LOADER })
  const hideLoader = () => dispatch({ type: HIDE_LOADER })
  const setError = data => dispatch({ type: SET_ERROR, data })
  const changePathHandle = useCallback(
    path => {
      showLoader()
      setTimeout(() => {
        history.push(path)
        hideLoader()
      }, 900)
    },
    [history],
  )

  const fetchItems = useCallback(async () => {
    showLoader()
    try {
      const result = await fetch(
        `json/${state.menuCategory}/${state.subMenuCategory}/data.json`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )
        .then(response => response.json())
        .then(jsonResponse => jsonResponse.items)
      setTimeout(() => {
        dispatch({ type: FETCH_ITEMS_BY_CATEGORY, result })
        hideLoader()
      }, 400)
    } catch (error) {
      setError(error)
      console.log(error, 'Fetch items ERROR')
      hideLoader()
    }
  }, [state])

  const setSubMenuCategory = useCallback(data => {
    try {
      dispatch({ type: SET_MENU_SUB_CATEGORY, data })
    } catch (error) {
      console.log(error, 'setSubMenuCategory error')
    }
  }, [])

  const setMenuCategory = useCallback(data => {
    showLoader()
    try {
      setTimeout(() => {
        dispatch({ type: SET_MENU_CATEGORY, data })
        hideLoader()
      }, 400)
    } catch (error) {
      console.log(error, 'error setMenuCategory')
      hideLoader()
    }
  }, [])

  const setItemWeight = data => {
    try {
      dispatch({ type: SET_ITEM_WEIGHT, data })
    } catch (error) {
      console.log(error, 'setItemWeight error')
    }
  }

  const setItemVariants = data => {
    try {
      dispatch({ type: SET_ITEM_VARIANTS, data })
    } catch (error) {
      console.log(error, 'SET_ITEM_VARIANTS error')
    }
  }

  const clearCart = () => dispatch({ type: CLEAR_CART })

  const setCartItemsFromLocalStorage = () =>
    dispatch({ type: SET_CART_FROM_LOCAL_STORAGE })

  const addItemToCart = product =>
    dispatch({
      type: ADD_ITEM_TO_CART,
      product,
    })

  const setItemAmountToCart = useCallback(
    product =>
      dispatch({
        type: SET_ITEM_AMOUNT_TO_CART,
        product,
      }),
    [],
  )

  const removeItemFromCart = product =>
    dispatch({ type: REMOVE_ITEM_FROM_CART, product })

  const value = useMemo(
    () => ({
      subMenuCategory: state.subMenuCategory,
      menuCategory: state.menuCategory,
      menuItems: state.menuItems,
      cartItems: state.cartItems,
      loading: state.loading,

      fetchItems,
      setSubMenuCategory,
      setMenuCategory,
      changePathHandle,
      setItemWeight,
      setItemVariants,
      clearCart,
      removeItemFromCart,
      setCartItemsFromLocalStorage,
      addItemToCart,
      setItemAmountToCart,
    }),
    [
      state,
      fetchItems,
      setSubMenuCategory,
      setMenuCategory,
      changePathHandle,
      setItemAmountToCart,
    ],
  )

  return (
    <MenuPageContext.Provider value={value}>
      {children}
    </MenuPageContext.Provider>
  )
}
