
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';

import {
ADDITIONAL_COMPOTE, ADDITIONAL_MORS,
MENU_CATEGORY_HASH, PROMOTION,   SUB_CATEGORIES_HASH, } from '../../const/menu';
import {
  ADD_ITEM_TO_CART,
  CLEAR_CART,
  FETCH_ITEMS_BY_CATEGORY,
  HIDE_LOADER,
  REMOVE_ITEM_FROM_CART,
  SET_CART_FROM_LOCAL_STORAGE,
  SET_ERROR,
  SET_ITEM_AMOUNT_TO_CART,
  SET_ITEM_VARIANTS,
  SET_ITEM_WEIGHT,
  SET_MENU_CATEGORY,
  SET_MENU_SUB_CATEGORY,
  SHOW_LOADER,
} from './types';

function setSubMenuCategory(menuCategory) {
  switch (menuCategory) {
    case MENU_CATEGORY_HASH.PIES:
      return SUB_CATEGORIES_HASH.STANDARD_PIES;
    case MENU_CATEGORY_HASH.SEMIMANUFACTURES:
      return SUB_CATEGORIES_HASH.MANTI;
    case MENU_CATEGORY_HASH.NEW:
      return SUB_CATEGORIES_HASH.NEW_PIES;
    default:
      return '';
  }
}

export const reducer = (state, action) => {
  const setItemsToLocalStorage = (array) => {
    const newCartObject = {};
    newCartObject.items = array;
    localStorage.setItem('cart', JSON.stringify(newCartObject));
  };

  const handles = {
    [SET_MENU_SUB_CATEGORY]: (state, { data }) => ({
      ...state,
      subMenuCategory: data,
    }),
    [SET_MENU_CATEGORY]: (state, { data }) => ({
      ...state,
      menuCategory: data,
      subMenuCategory: setSubMenuCategory(data),
    }),
    [SET_ITEM_WEIGHT]: (state, { data }) => {
      const newItems = state.menuItems;
      newItems[data.id].total = Math.ceil(newItems[data.id].price * data.value);
      newItems[data.id].totalOldPrice = Math.ceil(newItems[data.id].oldPrice * data.value);
      newItems[data.id].weight = data.value;

      return {
        ...state,
        menuItems: newItems,
      };
    },
    [SET_ITEM_VARIANTS]: (state, { data }) => {
      const newItems = state.menuItems;
      if (!newItems[data.id]) return { ...state };

      if (newItems[data.id].selectedVariants) {
        newItems[data.id].selectedVariants = {
          ...newItems[data.id].selectedVariants,
          [data.keyVariant]: newItems[data.id].variants[data.keyVariant].filter(
            (el) => el.value === data.value,
          ),
        };
      } else {
        newItems[data.id].selectedVariants = {
          [data.keyVariant]: newItems[data.id].variants[data.keyVariant].filter(
            (el) => el.value === data.value,
          ),
        };
      }
      return {
        ...state,
        menuItems: newItems,
      };
    },
    [SHOW_LOADER]: (state) => ({
      ...state,
      loading: true,
    }),
    [HIDE_LOADER]: (state) => ({
      ...state,
      loading: false,
    }),
    [FETCH_ITEMS_BY_CATEGORY]: (state, { result }) => ({
      ...state,
      menuItems: [...result],
    }),
    [SET_ERROR]: (state, { data }) => ({
      ...state,
      errors: data,
    }),
    [ADD_ITEM_TO_CART]: (state, { product }) => {
      const copyStateArray = uniq(state.cartItems);
      let flag = false;

      copyStateArray.forEach((item, index) => {
        const itemCopy = { ...item };
        // delete itemCopy.count

        if (isEqual(itemCopy, product)) {
          copyStateArray[index].count = item.count + copyStateArray[index].count;
          flag = true;
        }
      });

      if (!flag) {
        // eslint-disable-next-line no-param-reassign
        product.count = product.count ?? 1;
        copyStateArray.push(product);
      }

      const isCartIncludesMors = !!copyStateArray.find(
        (el) => el.value === PROMOTION.MORS,
      );

      const isCartIncludesCompote = !!copyStateArray.find(
        (el) => el.value === PROMOTION.COMPOTE,
      );

      const total = copyStateArray.reduce(
        (a, b) => a + (Math.ceil(b.price * b.weight * b.count) || 0),
        0,
      );

      if (!isCartIncludesCompote && total > 1500 && total < 2000) {
        copyStateArray.push(ADDITIONAL_COMPOTE);
      } else if (isCartIncludesCompote && total < 1500) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.COMPOTE));
      }

      if (!isCartIncludesMors && total > 2000) {
        copyStateArray.push(ADDITIONAL_MORS);
        remove(copyStateArray, ((item) => item.value === PROMOTION.COMPOTE));
      } else if (isCartIncludesMors && total < 2000) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.MORS));
      }

      setItemsToLocalStorage(copyStateArray);

      return {
        ...state,
        cartItems: copyStateArray,
      };
    },
    [CLEAR_CART]: (state) => {
      localStorage.removeItem('cart');
      return {
        ...state,
        cartItems: [],
      };
    },
    [SET_ITEM_AMOUNT_TO_CART]: (state, { product }) => {
      const copyStateArray = state.cartItems;
      copyStateArray.forEach((item, i) => {
        const itemCopy = { ...item };
        delete itemCopy.count;

        if (isEqual(itemCopy, product)) {
          if (product.count <= 1) {
            copyStateArray[i] = { ...product, count: 1 };
          } else copyStateArray[i] = product;
        }
      });

      const isCartIncludesMors = !!copyStateArray.find(
        (el) => el.value === PROMOTION.MORS,
      );

      const isCartIncludesCompote = !!copyStateArray.find(
        (el) => el.value === PROMOTION.COMPOTE,
      );

      const isCartIncludesRaspberriesPie = !!copyStateArray.find(
        (el) => el.value === PROMOTION.RASPBERRIES_PIE,
      );

      const total = copyStateArray.reduce(
        (a, b) => a + (Math.ceil(b.price * b.weight * b.count) || 0),
        0,
      );

      if (isCartIncludesRaspberriesPie && total < 2000) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.RASPBERRIES_PIE));
      }

      if (!isCartIncludesCompote && total > 1500 && total < 2000) {
        copyStateArray.push(ADDITIONAL_COMPOTE);
      } else if (isCartIncludesCompote && total < 1500) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.COMPOTE));
      }

      if (!isCartIncludesMors && total > 2000) {
        copyStateArray.push(ADDITIONAL_MORS);
        remove(copyStateArray, ((item) => item.value === PROMOTION.COMPOTE));
      } else if (isCartIncludesMors && total < 2000) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.MORS));
      }

      setItemsToLocalStorage(copyStateArray);

      return {
        ...state,
        cartItems: copyStateArray,
      };
    },
    [SET_CART_FROM_LOCAL_STORAGE]: (state) => {
      const copyCartObj = JSON.parse(localStorage.getItem('cart'));
      return {
        ...state,
        cartItems: [...copyCartObj.items],
      };
    },
    [REMOVE_ITEM_FROM_CART]: (state, { product }) => {
      const copyStateArray = state.cartItems;
      const index = copyStateArray.findIndex(
        (x) => x.value === product.value && x.weight === product.weight,
      );
      if (index > -1) {
        copyStateArray.splice(index, 1);
      }

      const isCartIncludesMors = !!copyStateArray.find(
        (el) => el.value === PROMOTION.MORS,
      );

      const isCartIncludesCompote = !!copyStateArray.find(
        (el) => el.value === PROMOTION.COMPOTE,
      );

      const isCartIncludesRaspberriesPie = !!copyStateArray.find(
        (el) => el.value === PROMOTION.RASPBERRIES_PIE,
      );
      const total = copyStateArray.reduce(
        (a, b) => a + (Math.ceil(b.price * b.weight * b.count) || 0),
        0,
      );

      if (!isCartIncludesCompote && total > 1500 && total < 2000 && product.value !== PROMOTION.COMPOTE) {
        copyStateArray.push(ADDITIONAL_COMPOTE);
      } else if (isCartIncludesCompote && total < 1500) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.COMPOTE));
      }

      if (isCartIncludesRaspberriesPie && total < 2000) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.RASPBERRIES_PIE));
      }

      if (!isCartIncludesMors && total > 2000 && product.value !== PROMOTION.MORS) {
        copyStateArray.push(ADDITIONAL_MORS);
        remove(copyStateArray, ((item) => item.value === PROMOTION.COMPOTE));
      } else if (isCartIncludesMors && total < 2000) {
        remove(copyStateArray, ((item) => item.value === PROMOTION.MORS));
      }

      setItemsToLocalStorage(copyStateArray);

      return {
        ...state,
        cartItems: copyStateArray,
      };
    },
    DEFAULT: (state) => state,
  };

  const handler = handles[action.type] || handles.DEFAULT;
  return handler(state, action);
};
