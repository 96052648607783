import { MENU_CATEGORY_HASH, SUB_CATEGORIES_HASH } from '../const/menu';

const menuCategories = {
  [MENU_CATEGORY_HASH.NEW]: 'Новинки',
  [MENU_CATEGORY_HASH.POPULAR]: 'Популярное',
  [MENU_CATEGORY_HASH.PIES]: 'Пироги',
  [MENU_CATEGORY_HASH.PIZZA]: 'Пицца',
  [MENU_CATEGORY_HASH.SEMIMANUFACTURES]: 'Полуфабрикаты',
  [MENU_CATEGORY_HASH.DRINKS]: 'Напитки',

};

const subMenuCategories = {
  [SUB_CATEGORIES_HASH.STANDARD_PIES]: 'Пироги',
  [SUB_CATEGORIES_HASH.KISH]: 'КИШ',
  [SUB_CATEGORIES_HASH.FISH_PIES]: 'Рыбные пироги',
  [SUB_CATEGORIES_HASH.OSSETIAN_PIES]: 'Осентинские пироги',
  [SUB_CATEGORIES_HASH.SWEET_PIES]: 'Сладкие пироги',
  [SUB_CATEGORIES_HASH.DUMPLING]: 'Пельмени',
  [SUB_CATEGORIES_HASH.OTHERS]: 'Разное',
  [SUB_CATEGORIES_HASH.MANTI]: 'Манты',
  [SUB_CATEGORIES_HASH.VARENIKI]: 'Вареники',
  [SUB_CATEGORIES_HASH.PUFF]: 'Пирожки',
  [SUB_CATEGORIES_HASH.SETS]: 'Сеты',
  [SUB_CATEGORIES_HASH.NEW_PIES]: 'Пироги',
  [SUB_CATEGORIES_HASH.NEW_PIZZA]: 'Пицца',

};

export default function getTextTranslate(type, text) {
  switch (type) {
    case 'menuCategories':
      return menuCategories[text];
    case 'subMenuCategories':
      return subMenuCategories[text];
    default:
      return '';
  }
}
