import React, { useCallback, useContext, useMemo, useState } from 'react'
import { withStyles, Divider } from '@material-ui/core'
import { DateTime } from 'luxon'
import toLowerCase from 'lodash/toLower'

import CalendarToday from '@material-ui/icons/CalendarToday'
import {
  Grid,
  TextField,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
} from '@material-ui/core'

import MuiPhoneNumber from 'material-ui-phone-number'
import { MenuPageContext } from '../../../context/MenuPageContext/context'

import { styles } from '../styles'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import trim from 'lodash/trim'
import { delay } from 'lodash'
import { ADDITIONAL_RASPBERRIES_PIE, PROMOTION } from '../../../const/menu'

const PICK_UP_TIME_HASH = {
  soon: 'Как можно скорее',
  inSomeTime: 'К определенному времени',
}

function MakeOrderBlock({ classes, onChangeLoading, onChangeResponse }) {
  const [errorToastMessage, setErrorToastMessage] = useState()
  const [successToastMessage, setSuccessToastMessage] = useState()
  const [emptyCartText, setEmptyCartText] = useState(
    'Ваша корзина пуста! Скорее добавьте в нее что-нибудь вкусное :)',
  )

  const { register, handleSubmit, errors, control, watch, getValues } =
    useForm()
  const { addItemToCart, cartItems, clearCart } = useContext(MenuPageContext)

  const summary = useCallback(() => {
    let total = 0

    if (!cartItems.length) {
      return { total, withShipping: 0, shipping: 0 }
    }

    if (cartItems.length > 1) {
      total = cartItems.reduce(
        (a, b) => a + (Math.ceil(b.price * b.weight * b.count) || 0),
        0,
      )
    } else {
      total = Math.ceil(
        cartItems[0]?.price * cartItems[0]?.weight * cartItems[0]?.count,
      )
    }

    return {
      total,
      shipping: total < 1000 ? 250 : total >= 1000 && total <= 3000 ? 150 : 0,
      withShipping:
        total < 1000
          ? total + 250
          : total >= 1000 && total <= 3000
            ? total + 150
            : total,
    }
  }, [cartItems])

  const handleHideErrorToast = useCallback(
    () => delay(() => setErrorToastMessage(), 2500),
    [],
  )
  const handleHideSuccessToast = useCallback(
    () => delay(() => setSuccessToastMessage(), 2500),
    [],
  )

  const handleApplyPromocode = useCallback(() => {
    const promocode = getValues('promocode')
    const isFreePieAdded = cartItems?.find(
      item => item.value === PROMOTION.RASPBERRIES_PIE,
    )

    if (!promocode || !trim(promocode)) {
      setErrorToastMessage('Промокод не может быть пустым')

      handleHideErrorToast()
      return
    }

    if (toLowerCase(promocode) !== 'нежность') {
      setErrorToastMessage('Такого промокода не существует')

      handleHideErrorToast()
      return
    }

    if (summary().total < 2000) {
      setErrorToastMessage('Сумма заказа должна быть равна или больше 2000р')

      handleHideErrorToast()
      return
    }

    if (isFreePieAdded) {
      setErrorToastMessage('Промокод уже применён!')

      handleHideErrorToast()
      return
    }

    addItemToCart(ADDITIONAL_RASPBERRIES_PIE)

    setSuccessToastMessage('Промокод успешно применен!')
    handleHideSuccessToast()
  }, [
    summary,
    addItemToCart,
    cartItems,
    getValues,
    handleHideErrorToast,
    handleHideSuccessToast,
  ])

  const onSubmit = useCallback(
    async data => {
      onChangeLoading(true)
      const products = cartItems.map(item => {
        return {
          ...item,
          img:
            process.env.PUBLIC_URL +
            `/productPhoto/${item.menuCategory}/${item.value}.jpg`,
        }
      })

      const summary = () => {
        let total = 0

        if (!cartItems.length) {
          return { total, withShipping: 0, shipping: 0 }
        }

        if (cartItems.length > 1) {
          total = cartItems.reduce(
            (a, b) => a + (Math.ceil(b.price * b.weight * b.count) || 0),
            0,
          )
        } else {
          total = Math.ceil(
            cartItems[0]?.price * cartItems[0]?.weight * cartItems[0]?.count,
          )
        }

        return {
          total,
          shipping:
            total < 1000 ? 250 : total >= 1000 && total <= 3000 ? 150 : 0,
          withShipping:
            total < 1000
              ? total + 250
              : total >= 1000 && total <= 3000
                ? total + 150
                : total,
        }
      }

      try {
        await axios({
          method: 'POST',
          url: `/api/create-order`,
          data: {
            ...data,
            order: products,
            summary: summary().total,
            summaryWithShipping: summary().withShipping,
            shipping: summary().shipping,
          },
        }).then(resp => {
          onChangeLoading(false)
          if (resp.data.success) {
            setEmptyCartText(
              'Ваш заказ успешно оформлен! В течение 15 минут с Вами свяжется оператор для уточнения заказа.',
            )
            onChangeResponse('Заказ успешно оформлен!')
            clearCart()
          } else window.alert(`${resp.data.message}`)
        })
      } catch (error) {
        onChangeResponse(
          `Произошла ошибка сервера! ${error.message}. Пожалуйста, повторите запрос позже\n\n Для заказа свяжитесь с нами по номеру телефона: +7(3462)64-00-05 или +7(922)425-90-05`,
        )
        onChangeLoading(false)
        console.log(error.message, 'BACKEND ERROR')
      }
    },
    [cartItems, clearCart, onChangeLoading, onChangeResponse],
  )

  return (
    <div className={classes.createOrderContainer}>
      {cartItems?.length > 0 ? (
        <>
          <Grid container className={classes.contentContainer}>
            <Grid item container direction="column">
              <Grid item className={classes.orderTextHeader}>
                <h1>Оформление заказа</h1>
              </Grid>
              <div className={classes.infoContainer}>
                <h2>
                  Доставка:{' '}
                  <span style={{ fontSize: 32, letterSpacing: '2px' }}>
                    {' '}
                    {summary().shipping}
                  </span>
                  руб.
                </h2>
                <h2>
                  Итого:
                  <span style={{ fontSize: 32, letterSpacing: '2px' }}>
                    {' '}
                    {summary().withShipping}
                  </span>
                  руб.
                </h2>
              </div>
            </Grid>
            <Divider classes={{ root: classes.dividerRootHorizontal }} />
            <Grid container alignItems="center" direction="column">
              <form
                className={classes.formContainer}
                onSubmit={handleSubmit(onSubmit)}
                id="make-order-form"
              >
                <Grid item container direction="column">
                  <Grid
                    item
                    container
                    justify="space-between"
                    className={classes.firstRow}
                  >
                    <Grid item className={classes.rowTitle}>
                      <h2>Адрес доставки:</h2>
                    </Grid>
                    <Grid item className={classes.streetNameFieldContainer}>
                      <Controller
                        defaultValue={''}
                        as={
                          <TextField
                            fullWidth
                            classes={{ root: classes.formTextFieldRoot }}
                            label="Улица*"
                            InputLabelProps={{
                              shrink: true,
                              classes: {
                                root: classes.inputLabel,
                              },
                            }}
                            error={!!errors.street}
                          />
                        }
                        control={control}
                        name="street"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item className={classes.houseFlatRow}>
                      <Grid
                        item
                        style={{ width: '25%', marginRight: 50 }}
                        className={classes.formFieldContainer}
                      >
                        <Controller
                          defaultValue={''}
                          as={
                            <TextField
                              label="Дом*"
                              InputLabelProps={{
                                shrink: true,
                                classes: {
                                  root: classes.inputLabel,
                                },
                              }}
                              error={!!errors.house}
                            />
                          }
                          control={control}
                          name="house"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ width: '25%', marginRight: 25 }}
                        className={classes.formFieldContainer}
                      >
                        <Controller
                          defaultValue={''}
                          name="flatNumber"
                          as={
                            <TextField
                              label="Квартира*"
                              InputLabelProps={{
                                shrink: true,
                                classes: {
                                  root: classes.inputLabel,
                                },
                              }}
                              type="number"
                              error={!!errors.flatNumber}
                            />
                          }
                          control={control}
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ width: '10%' }}
                        className={classes.formFieldContainer}
                      >
                        <Controller
                          defaultValue={''}
                          name="floor"
                          as={
                            <TextField
                              label="Этаж"
                              InputLabelProps={{
                                shrink: true,
                                classes: {
                                  root: classes.inputLabel,
                                },
                              }}
                              type="number"
                              error={!!errors.floor}
                            />
                          }
                          control={control}
                          rules={{ required: true }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item className={classes.formFieldContainer}>
                      <Controller
                        defaultValue={''}
                        render={props => (
                          <FormControlLabel
                            classes={{ root: classes.formControlLabelRoot }}
                            control={
                              <Checkbox
                                checked={props.value}
                                onChange={e => props.onChange(e.target.checked)}
                              />
                            }
                            label="Домофон"
                          />
                        )}
                        control={control}
                        name="intercom"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.secondRow}
                    item
                    container
                    justify="space-between"
                  >
                    <Grid item className={classes.rowTitle}>
                      <h2>Контактное лицо:</h2>
                    </Grid>
                    <Grid item className={classes.streetNameFieldContainer}>
                      <Controller
                        defaultValue={''}
                        name="name"
                        as={
                          <TextField
                            fullWidth
                            className={classes.textFieldStyles}
                            InputLabelProps={{
                              shrink: true,
                              classes: {
                                root: classes.inputLabel,
                              },
                            }}
                            label="Имя*"
                            error={!!errors.name}
                          />
                        }
                        control={control}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item className={classes.phoneTextFieldContainer}>
                      <Controller
                        defaultValue={''}
                        name="phone"
                        control={control}
                        as={
                          <MuiPhoneNumber
                            label="Номер телефона*"
                            InputLabelProps={{
                              shrink: true,
                              classes: {
                                root: classes.inputLabel,
                              },
                            }}
                            error={!!errors.phone}
                            defaultCountry={'ru'}
                            regions={'europe'}
                            className={classes.phoneTextField}
                          />
                        }
                        rules={{ required: true }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item className={classes.emailFieldContainer}>
                    <Controller
                      defaultValue={''}
                      name="email"
                      control={control}
                      as={
                        <TextField
                          fullWidth
                          label="E-mail"
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              root: classes.inputLabel,
                            },
                          }}
                          inputRef={register}
                        />
                      }
                    />
                  </Grid>

                  {/* PICKUP TIME */}
                  <Grid item className={classes.pickUpTimeContainer}>
                    <Grid className={classes.pickUpTimeRadioGroup} item>
                      <Grid item>
                        <h2>Время доставки:</h2>
                      </Grid>
                      <Grid item>
                        <Controller
                          defaultValue={''}
                          control={control}
                          name="pickUpTime"
                          as={
                            <FormControl
                              component="fieldset"
                              error={!!errors.pickUpTime}
                              className={classes.formControl}
                            >
                              <RadioGroup aria-label="pickUpTime">
                                <FormControlLabel
                                  value="Как можно скорее"
                                  control={<Radio color="primary" />}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                      root: classes.inputLabel,
                                    },
                                  }}
                                  label="Как можно скорее"
                                />
                                <FormControlLabel
                                  value="К определенному времени"
                                  control={<Radio color="primary" />}
                                  label="К определенному времени"
                                />
                              </RadioGroup>
                              <FormHelperText>
                                {errors.pickUpTime &&
                                  'Пожалуйста, выберите время доставки'}
                              </FormHelperText>
                            </FormControl>
                          }
                          rules={{ required: true }}
                        />
                      </Grid>
                    </Grid>
                    {watch('pickUpTime') === PICK_UP_TIME_HASH.inSomeTime && (
                      <Grid item className={classes.pickUpTimeInTimeContainer}>
                        <Controller
                          defaultValue={''}
                          name="pickUpTimeControlled"
                          control={control}
                          rules={{ required: true }}
                          as={
                            <TextField
                              label="Время доставки"
                              type="datetime-local"
                              inputProps={{
                                // min: new Date().toISOString().substring(0, 19),
                                min: DateTime.now().toFormat(
                                  "yyyy-LL-dd'T'HH:mm",
                                ),
                              }}
                              defaultValue={DateTime.now().toFormat(
                                "yyyy-LL-dd'T'HH:mm",
                              )}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                                classes: {
                                  root: classes.inputLabel,
                                },
                              }}
                              inputRef={register}
                            />
                          }
                        />
                        <FormHelperText error>
                          {errors.pickUpTimeControlled &&
                            'Пожалуйста, выберите дату и время доставки'}
                        </FormHelperText>
                        <p
                          style={{
                            color: 'green',
                            margin: 0,
                            textAlign: 'end',
                          }}
                        >
                          Нажмите на <CalendarToday fontSize="small" /> чтобы
                          выбрать время доставки
                        </p>
                      </Grid>
                    )}
                  </Grid>
                  {/* // */}

                  <Grid
                    item
                    container
                    justify="space-between"
                    className={classes.thirdRow}
                  >
                    <Grid className={classes.paymentBlock} item>
                      <Grid item>
                        <h2>Способ оплаты:</h2>
                      </Grid>
                      <Grid item>
                        <Controller
                          defaultValue={''}
                          control={control}
                          name="payment"
                          as={
                            <FormControl
                              component="fieldset"
                              error={!!errors.payment}
                              className={classes.formControl}
                            >
                              <RadioGroup aria-label="payment">
                                <FormControlLabel
                                  value="Наличные"
                                  control={<Radio color="primary" />}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                      root: classes.inputLabel,
                                    },
                                  }}
                                  label="Наличными курьеру"
                                />
                                <FormControlLabel
                                  value="Безналичный"
                                  control={<Radio color="primary" />}
                                  label="Безналичный расчёт"
                                />
                              </RadioGroup>
                              <FormHelperText>
                                {errors.payment &&
                                  'Пожалуйста, выберите способ оплаты'}
                              </FormHelperText>
                            </FormControl>
                          }
                          rules={{ required: true }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item className={classes.commentsBlock}>
                      <Grid item>
                        <h2>Комментарий к заказу</h2>
                      </Grid>
                      <Grid item>
                        <Controller
                          defaultValue={''}
                          name="comments"
                          control={control}
                          as={
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Пожалуйста, укажите здесь любую информацию, которая поможет курьеру быстрее доставить Вам заказ!"
                              multiline
                              rowsMax={4}
                              className={classes.textField}
                              variant="outlined"
                            />
                          }
                        />
                      </Grid>
                    </Grid>

                    {Object.keys(errors)?.length ? (
                      <div className={classes.requiredFiledsError}>
                        <p>Заполните обязательные поля</p>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item className={classes.promocodeBlock}>
                    <Grid item>
                      <h2>У вас есть Промокод?</h2>
                    </Grid>
                    <Grid item>
                      <Controller
                        defaultValue={''}
                        name="promocode"
                        control={control}
                        as={
                          <TextField
                            style={{ width: '100%', marginBottom: 16 }}
                            placeholder="Введите промокод здесь"
                            rowsMax={4}
                            className={classes.textField}
                            variant="outlined"
                          />
                        }
                      />
                    </Grid>

                    <Grid container sx={{ paddingTop: 12 }}>
                      <div className={classes.applyPromocodeContainer}>
                        <button
                          disabled={!watch('promocode')}
                          type="button"
                          className={classes.applyPromocodeButton}
                          onClick={handleApplyPromocode}
                        >
                          Применить промокод
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.makeOrderContainer}
            justify="space-between"
          >
            <div className={classes.fixedWidthContainer}>
              <button
                className={classes.makeOrderButton}
                type="submit"
                form="make-order-form"
              >
                Сделать заказ
              </button>
            </div>
          </Grid>
        </>
      ) : (
        <Grid
          container
          className={classes.contentContainer}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <h1>{emptyCartText}</h1>
          <button className={classes.goToMenuButton}>
            <a href="/">Перейти в меню</a>
          </button>
        </Grid>
      )}

      <div
        className={`errorAlert ${
          errorToastMessage ? 'displayed' : 'animated-hidden'
        }`}
        id="errorAlert"
      >
        <p>{errorToastMessage}</p>
      </div>
      <div
        className={`successToast ${
          successToastMessage ? 'displayed' : 'animated-hidden'
        }`}
        id="successToast"
      >
        <p>{successToastMessage}</p>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(MakeOrderBlock)
