import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { v4 as uuidv4 } from "uuid";

import size from "lodash/size";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles(() => ({
  selectDropdown: {
    minWidth: 70,
    background: "#ffffff",
    border: "1px solid #c7c7c7",
    borderRadius: "20px",
    height: "42px",
    font: "16px/40px 'Raleway'",
    fontWeight: "bold",
    paddingLeft: "13px",
  },
  rootButton: {
    padding: "0px !important",
    minWidth: "30px !important",
  },
}));

function SelectDropdown({ onChange, id, keyVariant, values }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(values[0] ?? null);

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (isEmpty(values)) return;
    onChange({ value: values[0].value, id, keyVariant });
  }, []);

  const handleClose = (e, value) => {
    if (value !== "backdropClick") {
      onChange({ value: value.value, id, keyVariant });
      setSelectedOption(value);
    }
    setAnchorEl(null);
  };

  if (!size(values)) return null;

  return (
    <>
      <div className={classes.selectDropdown} onClick={handleClick}>
        <span>{selectedOption?.label}</span>
        <Button classes={{ root: classes.rootButton }}>
          <ArrowDropDownIcon />
        </Button>
      </div>

      <Menu
        transformOrigin={{
          vertical: -50,
          horizontal: 0,
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {values.map((el) => (
          <MenuItem
            key={uuidv4()}
            disabled={selectedOption?.value === el.value}
            onClick={(e) => handleClose(e, el)}
          >
            {el.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default SelectDropdown;
