import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 100px)',
    background: '#ffff',
  },
  contentContainer: {
    width: '75%',
    margin: '0 auto',
    paddingTop: 50,
    '@media screen and (max-width: 930px)': {
      width: '95%',
    },
  },
  infoContainer: {
    lineHeight: '50px',
    '& h2': {
      fontSize: '4rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
}))

function PaymentAndShippingPage() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid
        alignItems="center"
        className={classes.contentContainer}
        direction="column"
        container
      >
        <div className={classes.infoContainer}>
          <h1>Договор публичной оферты</h1>
          <ol>
            <li>
              Термины и определения
              <ol>
                <li>
                  В настоящей оферте, если из контекста не следует иное,
                  нижеприведенные термины с заглавной буквы имеют следующие
                  значения
                  <ol>
                    <li>
                      «Курьерская служба» – лица, оказывающие услуги по доставке
                      Товаров от имени Продавца, или сотрудники Продавца.
                    </li>
                    <li>
                      «Ресторан» - место изготовления Товаров, принадлежащих
                      Продавцу. Адреса ресторанов указывается на Сайте.
                    </li>
                    <li>
                      «Продавец» – ИП Осокин Юрий Вячеславович (ИНН
                      860213816160, ОГРН 308860224800032), местонахождение по
                      адресу: г. Сургут, пр. Набережный 2/1 на Энтузиастов 40
                      (вход в Сабвэй). Полное фирменное наименование и место
                      нахождения Продавца указывается в настоящей оферте после
                      выбора Ресторанов на Сайте. Адрес изготовителя Товара
                      указывается на Сайте, данный адрес соответствует
                      выбранному Ресторану.
                    </li>
                    <li>
                      «Покупатель» – любое дееспособное лицо, намеренное
                      заказать или приобрести, либо заказывающий, приобретающий
                      или использующий Товары исключительно для личных,
                      семейных, домашних или иных нужд, не связанных с
                      осуществлением предпринимательской деятельности.
                    </li>
                    <li>
                      «Сайт» – https://obojayka86.ru/ Сайт включает мобильную
                      версию и десктопную версию.
                    </li>
                    <li>«Телефон» – 8 (3462) 64-00-05</li>
                    <li>
                      «Товары» - перечень продукции Продавца, представленный на
                      Сайте, которые изготавливаются исключительно по заказу
                      Покупателя. Товары могут отличаться от изображения на
                      Сайте, в Ресторане. Потребительские свойства Товара
                      указаны на Сайте.
                    </li>
                  </ol>
                </li>
                <li>
                  Для целей настоящей оферты термины и определения в
                  единственном числе относятся также и к терминам и определениям
                  во множественном числе.
                </li>
                <li>
                  Условия настоящей оферты действуют для Сайта, если иное прямо
                  не предусмотрено настоящей офертой.
                </li>
              </ol>
              <li>
                Общие положения
                <ol>
                  <li>
                    Продавец и Покупатель гарантируют, что обладают необходимой
                    право- и дееспособностью, а также всеми правами,
                    необходимыми для заключения и исполнения договора розничной
                    купли-продажи дистанционным способом.
                  </li>
                  <li>
                    Покупатель обязуется ознакомиться с условиями настоящей
                    оферты, с информацией, указанной на сайте в отношении
                    Товаров, Пользовательским соглашением.
                  </li>
                  <li>
                    Продавец оставляет за собой право вносить изменения в
                    настоящую оферту, в связи с чем Покупатель обязуется
                    самостоятельно отслеживать наличие изменений в настоящей
                    оферте.
                  </li>
                  <li>
                    При оформлении заказа Товаров Покупатель тем самым
                    подтверждает, что он исполнил обязанности, предусмотренные
                    пунктом 2.2. настоящей оферты.
                  </li>
                  <li>
                    Договор розничной купли-продажи дистанционным способом
                    считается заключенным с момента получения Продавцом
                    сообщения о намерении Покупателя приобрести Товары.
                  </li>
                  <li>
                    Срок, в течение которого действует предложение о заключении
                    договора розничной купли-продажи дистанционным способом,
                    является бессрочным.
                  </li>
                </ol>
              </li>
              <li>
                Предмет Договора
                <ol>
                  <li>
                    Продавец продает Товары, указанные на Сайте, по ценам,
                    представленным на Сайте, и/или Продавец продает Товары,
                    представленные в Ресторане, по ценам указанным в ресторане
                    соответственно; доставляет Товар Курьерской службой, а
                    Покупатель производит оплату и принимает Товары в
                    соответствии с условиями настоящей оферты.
                  </li>
                  <li>
                    Право собственности и риск случайной гибели/повреждения
                    Товаров переходит к Покупателю в момент приемки Товаров
                    Покупателем.
                  </li>
                </ol>
              </li>
              <li>
                Уведомления Покупателя
                <ol>
                  <li>
                    Продавец (или иное лицо по поручению Продавца) вправе
                    отправлять сервисные сообщения (в том числе
                    push-уведомления), информирующие Покупателя о совершенном им
                    заказе, его составе, этапах его обработки и о готовности
                    заказа; такие сервисные сообщения отправляются
                    автоматически, не могут быть отклонены Покупателем,
                    направлены на контроль качества оказания услуг Покупателю и
                    его информирование о надлежащем исполнении Продавцом своих
                    обязательств по договору купли-продажи дистанционным
                    способом.
                  </li>
                </ol>
              </li>
              <li>
                Оформление заказа Товаров
                <ol>
                  <li>
                    Заказ Покупателя может быть оформлен по Телефону и/или
                    посредством заполнения электронной формы заказа на Сайте, в
                    Ресторане.
                  </li>
                  <li>
                    Покупатель обязуется предоставить достоверные данные,
                    необходимые и достаточные для исполнения договора розничной
                    купли-продажи Товаров дистанционным способом.
                  </li>
                  <li>
                    В случае возникновения у Покупателя дополнительных вопросов,
                    касающихся Товаров, перед оформлением заказа, Покупатель
                    должен обратиться к Продавцу по Телефону для получения
                    необходимой информации, в соответствии с режимом работы,
                    указанным на Сайте.
                  </li>
                  <li>
                    Покупатель может заказать только те Товары, которые есть в
                    наличии у Продавца в момент оформления заказа Товаров у
                    Продавца.
                  </li>
                  <li>
                    Покупатель имеет право удалить ингредиенты из состава
                    Товара, при наличии технической возможности, при этом цена
                    на Товар не меняется.
                  </li>
                  <li>
                    Покупатель не имеет право изменить состав заказа после его
                    оформления на Сайте.
                  </li>
                  <li>
                    Покупатель имеет право изменить состав Товаров на Сайте (при
                    наличии такой возможности).
                  </li>
                  <li>
                    Покупатель не вправе изменить состав Товаров по Телефону, в
                    Ресторане.
                  </li>
                  <li>
                    При оформлении заказа по Телефону Продавец вправе
                    осуществлять аудиозапись телефонного разговора с
                    Пользователем, если указанные действия необходимы Продавцу
                    для исполнения обязанностей, предусмотренных настоящей
                    офертой, и оценки качества оказанных услуг, без получения от
                    Пользователя согласия на совершение указанных действий.
                  </li>
                  <li>
                    При оформлении заказа посредством заполнения электронной
                    формы заказа на Сайте или Покупатель создает учетную запись
                    в соответствии с Пользовательским соглашением, указанным на
                    Сайте.
                  </li>
                  <li>
                    Если у Продавца отсутствует необходимое количество или
                    ассортимент заказанных Покупателем Товаров, Продавец
                    информирует об этом Покупателя по телефону в течение 60
                    минут после получения заказа Товаров от Покупателя.
                    Покупатель вправе согласиться принять Товары в ином
                    количестве или ассортименте, либо аннулировать свой заказ
                    Товаров. В случае неполучения ответа Покупателя Продавец
                    вправе аннулировать заказ Покупателя в полном объеме.
                  </li>
                  <li>
                    Продавец вправе временно приостановить прием заказов Товаров
                    в случае технических неполадок, не позволяющих принять
                    заказа Товаров, или отсутствия ингредиентов, необходимых для
                    изготовления Товаров.
                  </li>
                </ol>
              </li>
              <li>
                Доставка Товаров
                <ol>
                  <li>
                    Доставка Товаров осуществляется Курьерской службой по
                    фактическому адресу, указанному Покупателем, при оформлении
                    заказа или выборкой Товаров Покупателем (самовывозом).
                  </li>
                  <li>
                    Доставка Товаров в пределах зоны доставки осуществляется в
                    соответствии с режимом работы Продавца, указанным на Сайте.
                  </li>
                  <li>
                    Доставка Товаров по адресам, не входящим в зону доставки, не
                    осуществляется.
                  </li>
                  <li>
                    В случае нарушения установленных сроков доставки Товаров, в
                    силу обстоятельств непреодолимой силы («форс-мажор») в
                    соответствии с Разделом 9 настоящей оферты Продавец не
                    считается просрочившим доставку.
                  </li>
                  <li>
                    Доставка осуществляется при условии заказа Товаров на сумму
                    минимального заказа. Сумма минимального заказа определяется
                    Продавцом в одностороннем порядке и указывается на Сайте.
                  </li>
                  <li>
                    Продавец вправе отказаться от исполнения договора розничной
                    купли-продажи дистанционным способом в том случае, если
                    Курьерская служба по прибытии к фактическому адресу доставки
                    Покупателя, не может осуществить фактическую передачу
                    доставленных Товаров по вине Покупателя (Покупатель в
                    течение 10 минут не отвечает на телефонный вызов или не
                    открывает дверь Курьерской службе).
                  </li>
                </ol>
              </li>
              <li>
                Оплата Товаров
                <ol>
                  <li>
                    Цены на Товары определяются Продавцом в одностороннем
                    порядке и указываются на Сайте. Цена на заказанный
                    Покупателем Товар изменению не подлежит.
                  </li>
                  <li>
                    Оплата Товаров Покупателем производится в рублях Российской
                    Федерации одним из следующих способов:
                    <ol>
                      <li>
                        оплата осуществляется по факту получения Товаров
                        наличными денежными средствами, оплата наличными
                        денежными средствами ограничена максимальной суммой.
                        Максимальная сумма заказа, при которой возможна оплата
                        наличными денежными средствами, определяется Продавцом в
                        одностороннем порядке и указывается только на Сайте. На
                        дату публикации настоящей оферты составляет 7 тысяч
                        рублей. Заказ превышающий размер указанной максимальной
                        суммы оплачивается в соответствии с п.7.2.2. настоящей
                        оферты;
                      </li>
                      <li>
                        безналичными расчетами банковской картой на Сайте. В
                        соответствии с положением ЦБ РФ "Об эмиссии банковских
                        карт и об операциях, совершаемых с использованием
                        платежных карт" № 266-П от 24.12.2004 года операции по
                        банковским картам совершаются держателем карты либо
                        уполномоченным им лицом;
                      </li>
                      <li>
                        безналичными расчетами банковской картой по факту
                        получения Товаров, при условии оснащения Курьерской
                        службы мобильными POS-терминалами (о наличии возможности
                        произвести оплату Товаров данным способом следует
                        уточнять у Продавца).
                      </li>
                    </ol>
                  </li>

                  <li>
                    Выдача либо направление Покупателю кассового чека:
                    <ol>
                      <li>
                        На бумажном носителе, в случае совершения заказа Товаров
                        через Телефон и оплаты в порядке, предусмотренном
                        пунктом 7.2.1 настоящей оферты, пунктом 7.2.3 настоящей
                        оферты, если Покупатель в момент совершения заказа не
                        выполнил условия, предусмотренные пунктом 7.4. настоящей
                        оферты.
                      </li>
                      <li>
                        В электронном виде на электронную почту Покупателя, в
                        случае совершения заказа Товаров через Сайт и оплаты
                        Товаров в порядке, предусмотренном пунктом 7.2.2
                        настоящей оферты, если Покупатель выполнил условия
                        пункта 7.4. настоящей оферты.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Условием направления кассового чека в электронном виде
                    Покупателю является внесение Покупателем данных об адресе
                    электронной почты в личную учетную запись на Сайте при
                    регистрации в порядке, предусмотренном Пользовательским
                    соглашением.
                  </li>
                  <li>
                    Покупатель вправе в любое время обратиться к Продавцу с
                    просьбой об отмене транзакции при оплате заказа путем
                    безналичного расчета банковской картой. Решение о возврате
                    денежных средств принимается Продавцом с учетом требований
                    законодательства РФ и всех сложившихся обстоятельств.
                  </li>
                </ol>
              </li>
              <li>
                Возврат и обмен Товаров
                <ol>
                  <li>
                    Возврат Товара надлежащего качества не допускается,
                    поскольку Товар имеет индивидуально-определенные свойства, и
                    может быть использован исключительно приобретающим его
                    Покупателем.
                  </li>
                  <li>
                    Продавец обязан передать Покупателю Товары, которые
                    полностью соответствуют его заказу, качество которых
                    соответствует информации, представленной Покупателю при
                    заключении договора розничной купли-продажи дистанционным
                    способом, а также информации, доведенной до его сведения при
                    передаче Товаров (на этикетке или вкладыше, прикрепленных к
                    Товарам или их упаковке, либо другими способами,
                    предусмотренными для отдельных видов Товаров).
                  </li>
                  <li>
                    При получении Товаров Покупатель проверяет соответствие
                    полученных Товаров своему заказу, комплектность и отсутствие
                    претензий к внешнему виду доставленных Товаров в течение 10
                    (Десяти) минут после получения Товаров.
                  </li>
                  <li>
                    В случае получения некачественных Товаров, либо
                    несоответствия полученных Товаров заказанному (отсутствие
                    заявленных ингредиентов в Товаре), Покупатель вправе
                    потребовать замены таких Товаров Товарами надлежащего
                    качества сразу в течение 5 (Пяти) минут после проверки
                    соответствия полученного Товаров.
                  </li>
                  <li>
                    В случае невыполнения порядка предъявления претензии
                    Покупателя по внешнему виду Товаров и их комплектности,
                    предусмотренных пунктом 8.3, пунктом 8.4 настоящей оферты,
                    претензии не принимаются.
                  </li>
                </ol>
              </li>
              <li>
                Форс-мажор
                <ol>
                  <li>
                    Любая из Сторон освобождается от ответственности за полное
                    или частичное неисполнение своих обязательств по настоящей
                    оферте, если это неисполнение было вызвано обстоятельствами
                    непреодолимой силы. Обстоятельства непреодолимой силы
                    означают чрезвычайные события и обстоятельства, которые
                    Стороны не могли ни предвидеть, ни предотвратить разумными
                    средствами. Такие чрезвычайные события или обстоятельства
                    включают в себя, в частности: забастовки, наводнения,
                    пожары, землетрясения и иные стихийные бедствия, войны,
                    военные действия и так далее.
                  </li>
                </ol>
              </li>
              <li>
                Ответственность Сторон публичной оферты
                <ol>
                  <li>
                    Продавец не несет ответственности за ущерб, причиненный
                    Покупателю вследствие ненадлежащего использования им
                    Товаров, приобретённых у Продавца.
                  </li>
                  <li>
                    Продавец не отвечает за убытки Покупателя, возникшие в
                    результате неправильного заполнения заказа, в том числе
                    неправильного указания своих данных.
                  </li>
                  <li>
                    В случае злоупотребления Покупателем правами потребителя,
                    включая, но не ограничиваясь, если Покупатель отказался от
                    приемки и/или оплаты заказанного и доставленного Товара,
                    Продавец вправе реализовать свое право на самозащиту, а
                    именно: не принимать от него будущие заказы.
                  </li>
                  <li>
                    В случае одностороннего отказа Продавца от исполнения
                    договора розничной купли-продажи дистанционным способом в
                    соответствии с пунктом 6.6. настоящей публичной оферты,
                    Продавец вправе реализовать свое право на самозащиту,
                    взыскать убытки с Покупателя. В случае, если Покупатель
                    оплатил Товар до его доставки, оплаченная сумма не
                    возвращается.
                  </li>
                </ol>
              </li>
            </li>
          </ol>
        </div>
      </Grid>
    </div>
  )
}

export default PaymentAndShippingPage
