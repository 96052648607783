import {
  banner1, banner2, banner3, banner4, banner6,
} from '../../assets';

export const slideImages = [
  {
    img: banner4,
    heading: 'Всегда свежая выпечка. Вкусно как дома!',

  },
  {
    img: banner1,
    heading: 'Морс или компот в подарок! При заказе от 1500р. 1 литр компота или от 2000р. 1 литр морса В ПОДАРОК!',

  },
  {
    img: banner2,
    heading: 'Акция в День Рождения, скидка -10%!',

  },
  {
    img: banner3,
    heading: 'Акция! При покупке пирогов (только пирогов) на общую сумму 5000 р., в подарок сладкий клубничный пирог весом 1 кг.',

  },
  {
    img: banner6,
    heading: 'Сет Выгодный. Пироги Адыгейский сыр, зелень 1 кг, Сметанник 1 кг, Говядина, картофель, лук 1 кг, 35 см Пицца Жульен за 2299 руб. Экономия 15%!',

  },
];
