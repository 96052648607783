import { Grid } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 100px)',
    background: '#ffff',
  },
  contentContainer: {
    width: '75%',
    margin: '0 auto',
    paddingTop: 50,
    '@media screen and (max-width: 930px)': {
      width: '95%',
    },
  },
  infoContainer: {
    lineHeight: '40px',
    '& h2': {
      fontSize: '4rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },

  markedList: { listStyle: 'disc !important' },
}))

const PoliticsPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid
        container
        alignItems="center"
        direction="column"
        className={classes.contentContainer}
      >
        <div className={classes.infoContainer}>
          <h1>Соглашение на обработку персональных данных</h1>
          <div>Термины, применяемые в настоящем Соглашении:</div>
          <ul>
            <li className={classes.markedList}>
              Сайт – это совокупность текстов, графических элементов, дизайна,
              изображений, программного кода, фото- и видео материалов и иных
              результатов интеллектуальной деятельности, содержащихся в сети
              Интернет.
            </li>
            <li className={classes.markedList}>
              Администрация Сайта – это лицо, обладающее правами
              администрирования Сайта.
            </li>
            <li className={classes.markedList}>
              Пользователь – это любое лицо, осуществившее вход на Сайт и
              принявшее условия настоящего Соглашения, независимо от факта
              прохождения процедур регистрации и авторизации.
            </li>
          </ul>

          <div>
            Использование персональных данных Присоединяясь к настоящему
            Соглашению и оставляя свои данные на Сайте{' '}
            <b>«https://obojayka86.ru/»</b>, (далее – Сайт), путем заполнения
            полей формы (регистрации) Пользователь:
          </div>

          <ul>
            <li className={classes.markedList}>
              подтверждает, что все указанные им данные принадлежат лично ему;
            </li>
            <li className={classes.markedList}>
              подтверждает и признает, что им внимательно в полном объеме
              прочитано Соглашение и условия обработки его персональных данных,
              указываемых им в полях формы (регистрации), текст соглашения и
              условия обработки персональных данных ему понятны;
            </li>
            <li className={classes.markedList}>
              дает согласие на обработку Сайтом предоставляемых в составе
              информации персональных данных в целях заключения между ним и
              Сайтом настоящего Соглашения, а также его последующего исполнения;
            </li>
            <li className={classes.markedList}>
              дает согласие на обработку Сайтом предоставляемых персональных
              данных в целях регистрации Пользователя на Сайте;
            </li>
            <li className={classes.markedList}>
              выражает согласие с условиями обработки персональных данных без
              каких-либо оговорок и ограничений.
            </li>
          </ul>

          <div>
            Владельцем всей информации на сайте является ИП Осокин Юрий
            Вячеславович, ИНН 860213816160, ОГРН 308860224800032 Юр. адрес:
            628416, ХМАО-Югра, г. Сургут, пр. Набережный 2/1 на Энтузиастов 40
            (вход в Сабвэй)
          </div>

          <p>
            Пользователь дает свое согласие на обработку его персональных
            данных, а именно совершение действий, предусмотренных п. 3 ч. 1 ст.
            3 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных
            данных", и подтверждает, что, давая такое согласие, он действует
            свободно, своей волей и в своем интересе. Согласие Пользователя на
            обработку персональных данных является конкретным, информированным и
            сознательным.
          </p>

          <p>Обработке подлежат следующие персональные данные:</p>
          <ul>
            <li className={classes.markedList}>Имя Пользователя;</li>
            <li className={classes.markedList}>
              Электронный адрес Пользователя;
            </li>
            <li className={classes.markedList}>Номер телефона Пользователя;</li>
            <li className={classes.markedList}>Адрес Пользователя;</li>
          </ul>

          <p>
            Под обработкой персональных данных подразумевается следующий
            перечень действий с персональными данными: сбор, запись,
            систематизация, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передача (распространение,
            предоставление доступа), обезличивание, блокирование, удаление,
            уничтожение персональных данных.
          </p>
          <p>
            Данное согласие на обработку персональных данных действует
            бессрочно, но может быть отозвано субъектом персональных данных на
            основании распоряжения в форме подачи заявки на нашем Сайте.
          </p>
          <p>
            Администрация Сайта обязуется использовать персональные данные
            Пользователя, полученные в результате использования Сайта, в
            соответствии с требованиями законодательства о защите персональных
            данных, в том числе федерального закона №152-ФЗ от 27.07.2006 «О
            персональных данных» в редакции, действующей на момент обработки
            таких персональных данных.
          </p>

          <div>Дополнительные условия</div>
          <p>Пользователь проинформирован:</p>
          <ul>
            <li className={classes.markedList}>
              что настоящее Соглашение может быть им отозвано посредством
              распоряжения в форме подачи заявки на нашем Сайте.
            </li>
            <li className={classes.markedList}>
              что срок хранения и обработки его персональных данных
              устанавливается по усмотрению Администрации Сайта и может
              составлять до 10 лет с момента последнего размещения Пользователем
              информации. Пользователь гарантирует:
            </li>
            <li className={classes.markedList}>
              что информация, им предоставленная, является полной, точной и
              достоверной;
            </li>
            <li className={classes.markedList}>
              что при предоставлении информации не нарушается действующее
              законодательство Российской Федерации, законные права и интересы
              третьих лиц;
            </li>
            <li className={classes.markedList}>
              что вся предоставленная на Сайт информация заполнена Пользователем
              в отношении себя лично, все действия по регистрации на Сайте
              совершены непосредственно им;
            </li>
            <li className={classes.markedList}>
              что не были использованы чужие контактные данные, включая телефон,
              e-mail и т.п.;
            </li>
            <li className={classes.markedList}>
              что не создавались помехи в использовании Сайта другими
              Пользователями, в том числе посредством распространения
              компьютерных вирусов, неоднократного размещения дублирующей
              информации, одновременную отправку большого количества электронной
              почты, либо запросов к Сайту и т.п.
            </li>
          </ul>
          <p>
            Сайт не несет ответственности за использование (как правомерное, так
            и неправомерное) третьими лицами Информации, размещенной
            Пользователем на Сайте, включая её воспроизведение и
            распространение, осуществленные всеми возможными способами.
          </p>
          <p>
            Сайт имеет право вносить изменения в настоящее Соглашение. При
            внесении изменений в актуальной редакции указывается дата последнего
            обновления. Новая редакция Соглашения вступает в силу с момента ее
            размещения, если иное не предусмотрено новой редакцией Соглашения.
          </p>
          <p>
            Действующая редакция Соглашения находится на странице по адресу:
          </p>
          <p>https://obojayka86.ru/politics</p>
          <p>
            К настоящему Соглашению и отношениям между Пользователем и Сайтом,
            возникающим в связи с применением Соглашения, подлежит применению
            право Российской Федерации.
          </p>
        </div>
      </Grid>
    </div>
  )
}

export default PoliticsPage
