import { Grid } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 100px)',
    background: '#ffff',
  },
  contentContainer: {
    width: '75%',
    margin: '0 auto',
    paddingTop: 50,
    '@media screen and (max-width: 930px)': {
      width: '95%',
    },
  },
  infoContainer: {
    lineHeight: '50px',
    '& h2': {
      fontSize: '4rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    '& p': {
      fontSize: '2rem',
      color: 'black',
      '@media screen and (max-width: 670px)': {
        fontSize: '1.8rem',
      },
    },
  },
  coloredText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))

const PaymentAndShippingPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid
        container
        alignItems="center"
        direction="column"
        className={classes.contentContainer}
      >
        <div className={classes.infoContainer}>
          <h2>Доставка</h2>

          <p>
            У нас постоянно проходят{' '}
            <span className={classes.coloredText}>акции,</span> их вы можете
            найти в соответствующем разделе (акции не суммируются). Чтобы
            сделать заказ, оформите его на сайте или позвоните по контактному
            номеру телефона.
          </p>
          <p>
            <p>
              При заказе до 1000 рублей стоимость доставки по городу
              <span className={classes.coloredText}> 250 руб. </span>
              от 1000 рублей до 3000 стоимость доставки{' '}
              <span className={classes.coloredText}> 150 руб. </span>
              От 3000 рублей доставка{' '}
              <span className={classes.coloredText}> БЕСПЛАТНАЯ. </span>
              ГРЭС, Усольцева, Билецкого{' '}
              <span className={classes.coloredText}>+50 рублей </span> к
              стоимости доставки. эропорт, Белый Яр, Барсово, Солнечный{' '}
              <span className={classes.coloredText}>+100 руб.</span>{' '}
            </p>
            <p>
              Время приема заказов с{' '}
              <span className={classes.coloredText}>08:00 до 20:00 </span>{' '}
              ежедневно. Доставка на текущий день возможна при заказе до{' '}
              <span className={classes.coloredText}>20:00,</span> после 20:00
              заказ принимается на завтра или последующие дни.
            </p>
            <p>
              Все пироги готовятся индивидуально для Вас. Пироги будут
              доставлены горячими (сладкие охлажденными). После оформления Вами
              заказа, в течение{' '}
              <span className={classes.coloredText}>15 минут</span> Вам
              перезвонит менеджер для уточнения всех деталей заказа, если в
              течение этого времени звонок не поступил, значит мы не получили
              заказ, просим Вас перезвонить нам самостоятельно.
            </p>
            Звоните:
            <h4>
              <a className={classes.coloredText} href="tel:+73462640005">
                <span className={classes.coloredText}>+7 (3462)-64-00-05</span>
              </a>
            </h4>
            <h4>
              +7 (922) <span className={classes.coloredText}> 425-90-05</span>
              <p>
                <a className={classes.coloredText} href="/offer">
                  Договор публичной оферты
                </a>
              </p>
              <p>
                <a className={classes.coloredText} href="/politics">
                  Соглашение на обработку персональных данных
                </a>
              </p>
            </h4>
          </p>
        </div>
      </Grid>
    </div>
  )
}

export default PaymentAndShippingPage
