import { modalBackground } from '../../assets'

export const styles = theme => ({
  itemContainer: {
    color: theme.palette.primary.itemTextColor,
    backgroundColor: theme.palette.primary.linen,
    maxWidth: '23%',
    flexGrow: 0,
    flexBasis: '23%',
    textAlign: 'left',
    borderRadius: 12,
    overflow: 'hidden',
    margin: 10,
    marginBottom: 40,
    '& img': {
      // borderRadius: "50%",
      width: '100%',
      height: '100%',
      // maxHeight: 250,
      paddingTop: 0,
      objectFit: 'cover',
      alignSelf: 'center',
      cursor: 'pointer',
    },
  },
  itemLabel: {
    width: '100%',
    position: 'absolute',
    display: 'block',
    top: '5%',
    left: '73%',
  },
  itemImg: {
    width: '100%',
    padding: '0px !important',
    flexBasis: 'auto',
    maxHeight: 183,
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    borderBottom: `2px solid ${theme.palette.primary.brown}`,
    '&:hover': {
      '& div': {
        opacity: 1,
      },
    },
    '& div': {
      position: 'absolute',
      top: 0,
      opacity: 0,
      width: '100%',
      height: '100%',
      transition: 'all 0.3s',
      lineHeight: '1.3',
      backgroundColor: theme.palette.primary.translucentLightBackground,
      '& p': {
        margin: 0,
        padding: 20,
        fontSize: '1.7rem',
      },
    },
  },
  descriptionActive: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  itemInfo: {
    height: 90,
    // backgroundColor: "#f6f6f6",
    padding: '0 10px',
    // boxShadow: "inset 0 7px 8px -4px #e5e5e5",
    '& h2': {
      textAlign: 'center',
      wordBreak: 'break-word',
      transition: 'color 0.25s',
      fontWeight: 'bold',
      fontSize: 22,
      // minHeight: 52,
      '&:hover': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
    },
  },
  itemDescription: {
    maxWidth: '100%',
    color: '#666666',
    fontSize: 19,
    fontWeight: 300,
    fontFamily: theme.defaultFont,
    fontStretch: 'condensed',
    lineHeight: 1.2,
    // minHeight: 60,
  },
  itemPrice: {
    // backgroundColor: "#f6f6f6",
    fontWeight: 600,
    fontSize: 21,
  },
  addToCartButton: {
    border: `2px solid ${theme.palette.primary.activeRed}`,
    color: theme.palette.primary.activeRed,
    borderRadius: 30,
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: 13,
    fontSize: 18,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    letterSpacing: 3,
    fontFamily: theme.defaultFont,
    transition: 'all 0.25s',
    '&:hover': {
      color: '#ffff',
      backgroundColor: theme.palette.primary.activeRed,
      borderColor: theme.palette.primary.darkRed,
    },
  },
  addToCartButtonSmall: {
    border: `2px solid ${theme.palette.primary.activeRed}`,
    color: theme.palette.primary.red,
    borderRadius: 30,
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: 13,
    fontSize: 15,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    letterSpacing: 3,
    transition: 'all 0.25s',
    '&:hover': {
      color: '#ffff',
      backgroundColor: theme.palette.primary.activeRed,
      borderColor: theme.palette.primary.activeRed,
    },
    display: 'none',
  },

  // modal window styles

  dialogContent: {
    backgroundImage: `url(${modalBackground})`,
    paddingTop: '0px !important',
    padding: '0 0 40px',
    fontFamily: theme.defaultFont,
    '& img': {
      maxWidth: 800,
      width: '100%',
      height: '100%',
    },
    '& h1': {
      fontSize: 28,
    },
    '& p': {
      fontSize: 20,
    },
  },
  dialogInfoContainer: {
    color: theme.palette.primary.itemTextColor,
    padding: '0 7%',
    maxWidth: 800,
    width: '100%',
  },
  modalWindowPrice: {
    fontSize: 22,
  },
  closeIcon: {
    position: 'absolute',
    top: '2%',
    right: '2%',
    fontSize: '2.7rem',
    cursor: 'pointer',
  },
  orderModalButtonContainer: {
    alignSelf: 'flex-end',
  },
  cartItemAmount: {
    width: '100%',
  },
  cartItemAmountPuff: {},
  counterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  counterMinus: {
    flex: '0 0 30px',
    width: 30,
    height: 30,
    marginRight: 2,
    backgroundColor: '#ececec',
    borderRadius: 2,
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 16,
      height: 5,
      backgroundColor: '#868686',
      '-webkit-transform': 'translate(-50%,-50%)',
      ' -ms-transform': 'translate(-50%,-50%)',
      '-o-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    },
  },
  counterAmountInput: {
    height: 30,
    flex: '0 0 54px',
    width: 54,
    marginRight: 2,
    '& input': {
      width: '100%',
      border: 0,
      '-webkit-box-shadow': 'inset 0 2px 5px rgb(0 0 0 / 10%)',
      boxShadow: 'inset 0 2px 5px rgb(0 0 0 / 10%)',
      bordeRadius: 2,
      backgroundColor: '#f1f1f1',
      color: '#232323',
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'center',
      height: '100%',
      padding: 0,
      paddingLeft: 0,
    },
  },
  counterPlus: {
    flex: '0 0 30px',
    width: 30,
    height: 30,
    marginRight: 2,
    backgroundColor: '#ececec',
    borderRadius: 2,
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 15,
      height: 3,
      backgroundColor: '#868686',
      '-webkit-transform': 'translate(-50%,-50%)',
      ' -ms-transform': 'translate(-50%,-50%)',
      '-o-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 3,
      height: 15,
      backgroundColor: '#868686',
      '-webkit-transform': 'translate(-50%,-50%)',
      ' -ms-transform': 'translate(-50%,-50%)',
      '-o-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    },
  },

  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  salePrice: {
    color: theme.palette.primary.darkGreyText,
    textDecoration: 'line-through',
    marginRight: 10,
    fontSize: '18px !important',
  },
  mainPrice: {
    color: theme.palette.primary.activeRed,
  },

  // responsive styles
  '@media screen and (max-width: 1220px)': {
    itemInfo: { height: 80 },
  },
  '@media screen and (max-width: 1270px)': {
    itemContainer: {
      maxWidth: '30%',
      flexGrow: 0,
      flexBasis: '30%',
    },
  },
  '@media screen and (max-width: 830px)': {
    itemContainer: {
      maxWidth: '40%',
      flexGrow: 0,
      flexBasis: '40%',
    },
  },
  '@media screen and (max-width: 600px)': {
    itemDescription: {
      lineHeight: '1.7',
    },
    itemContainer: {
      flexGrow: 0,
      maxWidth: '80%',
      flexBasis: '80%',
      margin: '10px auto',
    },
    itemInfo: {
      height: 'max-content',
    },
    itemImg: {
      maxHeight: 280,
    },
    paperStyle: {
      margin: 10,
    },
    mainPrice: {
      fontSize: '22px !important',
    },
    salePrice: {
      fontSize: '20px !important',
    },
  },
  '@media screen and (max-width: 350px)': {
    itemContainer: {
      flexGrow: 0,
      maxWidth: '93%',
      flexBasis: '93%',
      margin: '0 auto',
      marginBottom: 20,
    },
  },
})
