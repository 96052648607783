import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'

import { forEach } from 'lodash'

import ProductInfoModalWindow from '../../components/ProductInfoModalWindow'
import { SUB_CATEGORIES_HASH } from '../../const/menu'
import { MenuPageContext } from '../../context/MenuPageContext/context'
import DescriptionBlock from './Components/DescriptionBlock'
import SelectDropdown from './Components/SelectDropdown'
import { styles } from './styles'

function MenuProducts({ selectedFilter, classes }) {
  const {
    addItemToCart,
    subMenuCategory,
    menuItems,
    menuCategory,
    setItemWeight,
    setItemVariants,
    setItemAmountToCart,
  } = useContext(MenuPageContext)

  const [visible, setVisible] = useState(false)
  const [modalWindowInfo, setModalWindowInfo] = useState(null)
  const [openModalWindow, setOpenModalWindow] = useState(false)

  const addToCartHandle = element => {
    addItemToCart({ ...element, subMenuCategory, menuCategory })
    setVisible(true)
    setTimeout(() => {
      setVisible(false)
    }, 600)
  }

  const handleChangeItemWeight = data => {
    setItemWeight(data)
  }

  const handleOpenModalInfo = item => () => {
    setModalWindowInfo({ ...item, subMenuCategory, menuCategory })
    setOpenModalWindow(true)
  }

  const handleChangeItemVariant = data => {
    setItemVariants(data)
  }

  const doughDropdownValues = useCallback(
    item => {
      if (!menuItems) return []
      let values = []
      forEach(menuItems, menuItem => {
        if (item.value === menuItem.value && menuItem?.variants?.dough) {
          values = [...menuItem?.variants?.dough]
        }
      })
      return values
    },
    [menuItems],
  )

  const fillingDropdownValues = useCallback(
    item => {
      if (!menuItems) return []
      let values = []
      forEach(menuItems, menuItem => {
        if (item.value === menuItem.value && menuItem?.variants?.filling) {
          values = [...menuItem?.variants?.filling]
        }
      })
      return values
    },
    [menuItems],
  )

  const itemCounter = useCallback(
    item => {
      if (!item) return null
      return (
        <Grid
          className={
            subMenuCategory === SUB_CATEGORIES_HASH.PUFF
              ? classes.cartItemAmountPuff
              : classes.cartItemAmount
          }
          data-name="Кол-во"
          item
        >
          <div className={classes.counterContainer}>
            <div
              className={classes.counterMinus}
              onClick={() => {
                if (item.count === 1 || item.count === item.minCount) return

                setItemAmountToCart({
                  ...item,
                  count: --item.count,
                })
              }}
            />
            <div className={classes.counterAmountInput}>
              <input type="number" value={item.count} disabled />
            </div>
            <div
              className={classes.counterPlus}
              onClick={() =>
                setItemAmountToCart({
                  ...item,
                  count: ++item.count,
                })
              }
            />
          </div>
        </Grid>
      )
    },
    [classes, setItemAmountToCart, subMenuCategory],
  )

  const itemsBySelectedFilter = useMemo(
    () =>
      selectedFilter && menuItems
        ? menuItems.filter(item => !!item[selectedFilter])
        : menuItems,
    [selectedFilter, menuItems],
  )

  return (
    <>
      <ProductInfoModalWindow
        addToCartHandle={addToCartHandle}
        classes={classes}
        modalWindowInfo={modalWindowInfo}
        openModalWindow={openModalWindow}
        setOpenModalWindow={setOpenModalWindow}
        isMenuPage
      />

      {itemsBySelectedFilter?.length > 0 &&
        itemsBySelectedFilter.map((item, index) => (
          <Grid
            alignItems="center"
            className={classes.itemContainer}
            direction="column"
            justify="space-between"
            key={`menu-item-${index}`}
            sm={3}
            spacing={2}
            container
            item
            xs
          >
            <Grid className={classes.itemImg} item xs>
              {item.isNew && (
                <img
                  alt="menu-item-label"
                  className={classes.itemLabel}
                  src="/productPhoto/promotion/newLabel.png"
                  style={{ width: '70px', height: '70px' }}
                />
              )}

              {item.isPopular && (
                <img
                  alt="menu-item-label"
                  className={classes.itemLabel}
                  src="/productPhoto/promotion/popularLabel.png"
                  style={{ width: '70px', height: '70px' }}
                />
              )}

              <img
                alt="menu-item"
                src={`${process.env.PUBLIC_URL}/productPhoto/${menuCategory}/${item.value}.jpg`}
                style={{ width: '100%' }}
              />
              <DescriptionBlock
                classes={classes}
                description={item.description}
                onOpenModal={handleOpenModalInfo(item)}
              />
            </Grid>
            <Grid className={classes.itemInfo} item>
              <h2 onClick={handleOpenModalInfo(item)}>{item.title}</h2>
            </Grid>
            <Grid
              alignItems="center"
              className={classes.itemPrice}
              justify="space-between"
              container
              item
            >
              {item.oldPrice ? (
                <div className={classes.priceContainer}>
                  <p className={classes.salePrice}>
                    {item.totalOldPrice ?? item.oldPrice} руб.
                  </p>
                  <p className={classes.mainPrice}>
                    {item.total ?? item.price} руб.
                  </p>
                </div>
              ) : (
                <p>{item.total ?? item.price} руб.</p>
              )}
              {subMenuCategory === SUB_CATEGORIES_HASH.PUFF ||
              subMenuCategory === SUB_CATEGORIES_HASH.SETS ? (
                itemCounter(item)
              ) : (
                <SelectDropdown
                  id={index}
                  values={item.weightValues ?? []}
                  onChange={handleChangeItemWeight}
                />
              )}
            </Grid>
            {doughDropdownValues(item).length > 0 && (
              <Grid
                alignItems="center"
                className={classes.itemPrice}
                justify="space-between"
                container
                item
              >
                <p>Тесто</p>
                <SelectDropdown
                  id={index}
                  keyVariant="dough"
                  values={doughDropdownValues(item)}
                  onChange={handleChangeItemVariant}
                />
              </Grid>
            )}
            {fillingDropdownValues(item).length > 0 && (
              <Grid
                alignItems="center"
                className={classes.itemPrice}
                justify="space-between"
                container
                item
              >
                <p>Начинка</p>
                <SelectDropdown
                  id={index}
                  keyVariant="filling"
                  values={fillingDropdownValues(item)}
                  onChange={handleChangeItemVariant}
                />
              </Grid>
            )}
            {subMenuCategory !== SUB_CATEGORIES_HASH.PUFF &&
              subMenuCategory !== SUB_CATEGORIES_HASH.SETS &&
              itemCounter(item)}

            <Grid justify="center" style={{ paddingBottom: 20 }} container item>
              <button
                className={classes.addToCartButton}
                onClick={() => addToCartHandle(item)}
              >
                В корзину +
              </button>
            </Grid>
          </Grid>
        ))}
      <div
        className={`successToast ${visible ? 'displayed' : 'animated-hidden'}`}
        id="successToast"
      >
        <p>Добавлено в корзину</p>
      </div>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(MenuProducts)
