import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  bannerBlockText: {
    left: '5%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    right: 30,
    top: 40,
    fontFamily: theme.defaultFont,
    color: 'black',
  },

  heading: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    width: 320,
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 700,
    color: 'black',
  },

  highlight: {
    textAlign: 'center',
    float: 'left',
    background: 'white',
    borderRadius: 9,
    padding: 15,
    width: '100%',

  },

  '@media screen and (max-width: 670px) ': {
    bannerBlockText: { width: '75%', left: '9%', top: '-4%' },
    heading: {
      display: 'grid',
      alignItems: 'end',
      alignSelf: 'end',
      fontSize: '0,4rem',
      fontWeight: 'bold',
    },
  },

  '@media screen and (max-width: 360px) ': {
    bannerBlockText: {
      top: '-3%',
      left: '7%',
    },
    heading: {
      display: 'grid',
      alignItems: 'end',
      alignSelf: 'end',
      lineHeight: '25px',
    },
  },
}));

export default useStyles;
