import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  defaultFont: "'Neucha'",
  headingFont: "'Lobster'",
  ralewayFont: "'Raleway'",
  palette: {
    primary: {
      brown: '#532F23',
      orange: '#ff8432',
      main: '#ed8f7a',
      itemTextColor: '#414141',
      darkGreen: '#532F23',
      linen: '#f6f0ea',
      textColor: '#282828',
      darkGreyText: '#ababab',
      activeRed: '#ff3f3f',
      white: '#ffffff',
      black: '#000000',
      translucentLightBackground: 'rgba(255, 255, 255, 0.8)',
      translucentDarkBackground: 'rgba(0, 0, 0, 0.70)',
    },
    sideBar: {
      backgroundColor: '#162E44',
    },
    error: {
      main: '#f44336',
    },
  },
});
