import React from "react";
import { slideImages } from "./bannersImports";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import cn from "classnames";
import useStyles from "./styles";


const PROPERTIES = {
  duration: 3000,
  infinite: true,
};
function HeaderSlideShow() {
  const classes = useStyles();

  return (
    <div className={classes.slideshowCarousel}>
      <div className="slide-container">
        <Fade {...PROPERTIES}>
          {slideImages.map((item, index) => (
            <div key={index} className="each-fade">
              <div>
                <img alt="slide-banner" src={item.img} />
                <div className={classes.bannerBlockText}>
                  <div
                    className={cn(classes.heading, index === 0,
                    )}
                  >
                   <span className={classes.highlight}>{item.heading}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </div>
  );
}

export default HeaderSlideShow;
