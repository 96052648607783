import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  iconCountFixed: {
    position: 'absolute',
    top: '-12%',
    right: '-1%',
    paddingTop: 2,
    borderRadius: '50%',
    minWidth: 27,
    height: 25,
    fontSize: 23,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.activeRed,
    padding: 3,
  },
  headerContainer: {
    height: 90,
    background: '#ffffff',
    borderBottom: `1px solid ${theme.palette.primary.itemTextColor}`,
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: 100,
    transition: '.3s',
  },
  headerListName: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Pacifico',
    fontSize: 32,
    lineHeight: 1,
    color: theme.palette.primary.textColor,
    textDecoration: 'none',
  },
  // minHeight: 130,
  // fontSize: 20,
  // position: "relative",
  // padding: "10px 0",
  // height: "max-content",
  // width: "100%",
  // margin: "0 auto",
  // paddingBottom: 80,
  // "& a": {
  //   transition: "color 0.25s",
  //   "&:hover": {
  //     color: theme.palette.primary.red,
  //   },
  // },
  // "& a:-webkit-any-link": {
  //   color: "white",
  //   // color: "black",
  //   textDecoration: "none",
  // },
  headerBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 13,
    margin: '0 auto',
    width: '75%',
    position: 'relative',
  },
  contactBlockContainer: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  contactBlockChild: {
    flexBasis: '50%',
    maxWidth: '50%',

    color: theme.palette.primary.textColor,
    // color: "black",
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  phoneBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  phoneIcon: {
    paddingRight: 10,
  },
  phonesList: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: 0,
    },
  },
  phoneText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  logoHeader: {
    textAlign: 'center',
    '& img': { height: 120 },
  },
  cartBlockHeader: {
    textAlign: 'center',
    '& a': {
      color: 'white !important',
      transition: 'color 0.25s',
      '&:hover': {
        color: 'black',
      },
    },
  },
  headerList: {
    color: theme.palette.primary.textColor,
    listStyle: 'none',
    width: '97%',
    alignItems: 'center',
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'space-between',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.textColor,
    },
  },
  listBlock: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.2rem',
    '& a': {
      alignItems: 'center',
      display: 'flex',
    },
    '& svg': {
      paddingRight: 10,
    },
    '&:hover': {
      '& span': {
        transition: 'color 0.25s',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
  },
  activeHeaderLink: {
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  dividerRootVertical: {
    boxShadow: '2px 0 0 rgb(255 255 255 / 5%)',
    background: '#e4e4e4',
    height: 25,
    width: 1,
  },
  mobileDividers: {
    background: 'rgba(0, 0, 0, 0.3)',
    height: 1,
    width: '85%',
    marginBottom: 40,
  },
  hiddenSlideshowCarousel: {
    transition: 'all 0.25s',
    visibility: 'hidden',
    opacity: 0,
    height: 0,
  },
  slideshowCarousel: {
    visibility: 'visible',
    transition: 'all 0.7s',
    width: '65%',
    margin: '0 auto',
    paddingTop: 50,
    opacity: 1,
  },
  mobileHeaderContainer: { display: 'none' },
  iconCount: {
    position: 'absolute',
    top: '12%',
    right: -10,
    paddingTop: 2,
    borderRadius: '50%',
    minWidth: 20,
    height: 20,
    fontSize: 17,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    fontFamily: theme.ralewayFont,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.activeRed,
    padding: 3,
  },
  menuIconContaiiner: {
    width: 'calc(100vw - 52%)',
    height: 80,
  },
  '@media screen and (max-width: 1300px) ': {
    headerBlock: { width: '98%' },
  },
  '@media screen and (max-width: 970px)': {
    menuIcon: {
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 1000,
      left: '3%',
      fontSize: '3rem',
    },
    closeMenuIcon: {
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 1000,
      left: '3%',
      top: '2%',
      fontSize: '3rem',
    },
    mobileHeaderContainer: { display: 'flex' },

    mobileHeader: {
      padding: 20,
    },
    headerContainer: {
      padding: 0,
    },
    mobileList: {
      listStyle: 'none',
      backgroundColor: 'white',
      height: '100vh',
      width: 310,
      padding: '20px',
      paddingTop: 100,
      margin: 0,
      '& li': {
        cursor: 'pointer',
        fontSize: 19,
        paddingBottom: 10,
        '&:hover': {
          fontWeight: 700,
          transition: 'all 0.25s',
          color: theme.palette.primary.main,
          '& a': {
            fontWeight: 700,
            transition: 'all 0.25s',
            color: theme.palette.primary.main,
          },
        },
      },
      '& a:-webkit-any-link': {
        color: theme.palette.primary.itemTextColor,
        textDecoration: 'none',
      },
    },
    mobilePhonesList: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: 0,
        fontSize: '1.2rem',
        marginBottom: 15,
      },
    },
    mobileCartContainer: {
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.white,
      },
      position: 'absolute',
      top: '25%',
      right: '4%',
    },
    headerBlock: { display: 'none' },
    iconCount: {
      top: '-25%',
    },
    headerListName: {
      fontSize: '27px',
    },
  },
  '@media screen and (max-width: 670px)': {
    menuIconContaiiner: {
      width: '85%',
    },
    mobileList: { width: 260 },
  },
  '@media screen and (max-width: 360px)': {
    menuIconContaiiner: {
      width: '100%',
    },
  },
}))

export default useStyles
