export const styles = () => ({
  
  container: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: 'calc(100vh - 100px)',
    // backgroundColor: "#f3f5f6",
    // background: `url(${landingBackground})`,
    // background:
    //   "radial-gradient(circle, rgba(218,190,151,1) 25%, rgba(255,255,255,1) 100%)",
    background: '#ffff',
    width: '100%',
  },
  buttonScrollToTop: {
    backgroundColor: '#b1bd61',
    position: 'fixed',
    bottom: 0,
    right: '4%',
    cursor: 'pointer',
    padding: '15px',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
    '& svg': {
      fontSize: '2rem',
    },
  },
  contentContainer: { position: 'relative', width: '70%', margin: '0 auto' },
  loadingCircle: {
    marginTop: '14%',
    position: 'absolute',
    top: '90%',
    left: '25%',
    right: '25%',
  },
  menuCategoryImages: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 70px',
    flexWrap: 'wrap',
    cursor: 'pointer',
    // check me #d4a15c82
    '& img': {
      width: '30%',
      height: '100%',
      maxHeight: 237,
      borderRadius: '12px',
    },
  },

  '@media screen and (max-width: 1580px)': {
    contentContainer: {
      width: '80%',
    },
  },
  '@media screen and (max-width: 1330px)': {
    contentContainer: {
      width: '90%',
    },
  },
  '@media screen and (max-width: 750px)': {
    contentContainer: {
      width: '95%',
      alignItems: 'flex-start',
    },
  },
  '@media screen and (max-width: 676px)': {
    menuCategoryImages: {
      padding: 0,
      justifyContent: 'center',
      '& img': {
        width: '60%',
        paddingBottom: 44,
        borderRadius: '12px',
      },
    },
  },
  '@media screen and (max-width: 520px)': {
    menuCategoryImages: {
      '& img': {
        width: '80%',
      },
    },
  },
})
