import React, { useContext } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import AddShoppingCartSharpIcon from "@material-ui/icons/AddShoppingCartSharp";

import { MenuPageContext } from "../../context/MenuPageContext/context";
import { MENU_CATEGORY_HASH } from "../../const/menu";

const ModalWindow = ({
  classes,
  openModalWindow,
  setOpenModalWindow,
  modalWindowInfo,
  isMenuPage = false,
  addToCartHandle,
}) => {
  const { setItemAmountToCart } = useContext(MenuPageContext);
  return (
    <Dialog
      maxWidth="md"
      open={openModalWindow}
      classes={{
        paper: classes.paperStyle,
      }}
      onBackdropClick={() => setOpenModalWindow(!openModalWindow)}
    >
      <DialogContent className={classes.dialogContent}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setOpenModalWindow(!openModalWindow)}
        />
        {modalWindowInfo && (
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <img
                alt="menu-item"
                style={{ width: '100%' }}
                src={
                  process.env.PUBLIC_URL +
                  `/productPhoto/${modalWindowInfo.menuCategory}/${modalWindowInfo.value}.jpg`
                }
              />
            </Grid>
            <Grid item className={classes.dialogInfoContainer}>
              <Grid item>
                <h1>{modalWindowInfo?.title}</h1>
              </Grid>
              <Grid item>
                <p>{modalWindowInfo.description}</p>
              </Grid>
              <Grid
                item
                className={classes.modalWindowPrice}
                container
                justify="space-between"
                alignItems="center"
              >
                {isMenuPage ? (
                  <Grid
                    className={classes.itemPrice}
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    style={{ backgroundColor: "transparent" }}
                  >
                    {
                      modalWindowInfo.oldPrice ?
                        (
                          <div
                            className={classes.priceContainer}
                          >
                            <p className={classes.salePrice}>{modalWindowInfo.oldPrice} руб.</p>
                            <p className={classes.mainPrice}>{modalWindowInfo.price} руб.</p>
                          </div>) :
                        <p>{modalWindowInfo.price} руб.</p>
                    }
                    <button
                      className={classes.addToCartButton}
                      onClick={() => addToCartHandle(modalWindowInfo)}
                    >
                      В корзину +
                    </button>
                    <button
                      className={classes.addToCartButtonSmall}
                      onClick={() => addToCartHandle(modalWindowInfo)}
                    >
                      <AddShoppingCartSharpIcon />
                    </button>
                  </Grid>
                ) : (
                  <>
                    {
                      modalWindowInfo.oldPrice ?
                        (
                          <div
                            className={classes.priceContainer}
                          >
                            <p className={classes.salePrice}>{modalWindowInfo.oldPrice} руб.</p>
                            <p className={classes.mainPrice}>{modalWindowInfo.price} руб.</p>
                          </div>) :
                        <p>{modalWindowInfo.price} руб.</p>
                    }
                    {modalWindowInfo.menuCategory !== MENU_CATEGORY_HASH.PROMOTION && (
                      <div className={classes.counterContainer}>
                        <div
                          onClick={() => {
                            if (modalWindowInfo.count === 1 || modalWindowInfo.count === modalWindowInfo.minCount) return

                            setItemAmountToCart({
                              ...modalWindowInfo,
                              count: --modalWindowInfo.count,
                            })
                          }
                          }
                          className={classes.counterMinus}
                        ></div>
                        <div className={classes.counterAmountInput}>
                          <input
                            disabled
                            value={
                              modalWindowInfo.count < 1
                                ? 1
                                : modalWindowInfo.count
                            }
                            type="number"
                          />
                        </div>
                        <div
                          onClick={() =>
                            setItemAmountToCart({
                              ...modalWindowInfo,
                              count: ++modalWindowInfo.count,
                            })
                          }
                          className={classes.counterPlus}
                        ></div>
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ModalWindow;
