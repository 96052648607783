import React, { useContext, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import cn from 'classnames'
import MenuIcon from '@material-ui/icons/Menu'
import SvgIcon from '@material-ui/core/SvgIcon'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { logoImage } from '../../assets'

import { MenuPageContext } from '../../context/MenuPageContext/context'

const MobileHeader = ({ classes }) => {
  const [isOpened, setIsOpened] = useState(false)

  const { cartItems } = useContext(MenuPageContext)
  const history = useHistory()

  const scrollToMenu = () => {
    const el = document.getElementById('menu-tabs')
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    history.push('/')
    setIsOpened(false)
  }

  const handlToggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsOpened(open)
  }

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.mobileHeaderContainer}
    >
      <Grid
        item
        className={classes.menuIconContaiiner}
        container
        justify="space-evenly"
        alignItems="center"
      >
        <SvgIcon
          className={classes.menuIcon}
          children={<MenuIcon />}
          onClick={handlToggleDrawer(true)}
          htmlColor={'black'}
        />
        <span
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.headerListName}
        >
          <img width={80} height={80} src={logoImage} />
          Обожайкин
        </span>
        <Drawer
          anchor={'left'}
          open={isOpened}
          onClose={handlToggleDrawer(false)}
        >
          <SvgIcon
            className={classes.closeMenuIcon}
            children={<CloseIcon />}
            onClick={handlToggleDrawer(false)}
            htmlColor={'black'}
          />
          <ul
            className={cn(classes.mobileList, { 'show-mobile-list': isOpened })}
          >
            <li onClick={scrollToMenu}>Меню</li>
            <Divider classes={{ root: classes.mobileDividers }} />
            <li onClick={handlToggleDrawer(!isOpened)}>
              <Link to="/about">Контакты</Link>
            </li>
            <Divider classes={{ root: classes.mobileDividers }} />
            <li onClick={handlToggleDrawer(!isOpened)}>
              <Link to="/shipping-and-payment">Доставка и оплата</Link>
            </li>
            <Divider classes={{ root: classes.mobileDividers }} />
            <div style={{ height: 30 }}></div>
            <div className={classes.mobilePhonesList}>
              <p>
                <a href="tel:+73462640005">
                  <span className={classes.phoneText}>+7 (3462) 64-00-05</span>
                </a>
              </p>
              <p>
                +7 (922) <span className={classes.phoneText}> 425-90-05</span>
              </p>
            </div>
            <Divider classes={{ root: classes.mobileDividers }} />
            <p>
              С 08:00 до 20:00, г. Сургут, пр. Набережный 2/1 на Энтузиастов 40
              (вход в Сабвэй){' '}
            </p>
          </ul>
        </Drawer>
      </Grid>
      <Grid item className={classes.mobileCartContainer}>
        <Link to="/cart">
          <ShoppingCartOutlinedIcon fontSize="large" color="primary" />
          <span className={classes.iconCount}>{cartItems.length}</span>
        </Link>
      </Grid>
    </Grid>
  )
}
export default MobileHeader
