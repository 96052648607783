import { modalBackground } from '../../assets';

const itemResponsiveStyles = {
  margin: '0 auto 25px',
  flex: '1 1 auto',
  maxWidth: 500,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 10,
  alignItems: 'center',
  borderBottom: '1px solid black',
  '&::before': {
    content: 'attr(data-name) " :"',
    fontSize: 17,
    fontWeight: 600,
    color: 'black',
  },
};

export const styles = (theme) => ({
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  salePrice: {
    color: theme.palette.primary.darkGreyText,
    textDecoration: 'line-through',
    marginRight: 10,
  },
  mainPrice: {
    color: theme.palette.primary.activeRed,
    fontSize: '25px !important',
  },
  buttonScrollToTop: {
    backgroundColor: '#b1bd61',
    position: 'fixed',
    bottom: 0,
    right: '4%',
    cursor: 'pointer',
    padding: '15px',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
    '& svg': {
      fontSize: '2rem',
    },
  },
  contentContainer: {
    color: theme.palette.primary.itemTextColor,
    position: 'relative',
    width: '75%',
    margin: '0 auto',
    '@media screen and (max-width: 1300px) ': {
      width: '85%',
    },
    '@media screen and (max-width: 930px) ': {
      width: '95%',
    },
  },
  container: {
    background: '#ffff',
    // minHeight: "calc(100vh - 100px)",
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 50,
    backgroundRepeat: 'repeat',
    width: '100%',
    transition: 'all 0.4s step-end',
  },
  cartItemsContainer: {
    margin: '0 auto',
  },
  infoContainer: {
    width: '90%',
    lineHeight: '35px',
    fontFamily: theme.defaultFont,
    '& p': {
      fontSize: '2rem',
    },
  },
  tableContainer: {
    letterSpacing: 1,
    backgroundColor: theme.palette.primary.translucentLightBackground,
    padding: '13px 0',
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 17,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tableToplineItem: {
    flex: '1 1 auto',
  },
  tableToplineItemPrice: {
    flex: '0 0 170px',
    width: 170,
    maxWidth: 170,
  },
  tableToplineItemAmount: {
    flex: '0 0 140px',
    width: 140,
    maxWidth: 140,
  },
  activeRedText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  counterContainer: {
    display: 'flex',
  },
  counterMinus: {
    flex: '0 0 30px',
    width: 30,
    height: 30,
    marginRight: 2,
    backgroundColor: '#ececec',
    borderRadius: 2,
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 16,
      height: 5,
      backgroundColor: '#868686',
      '-webkit-transform': 'translate(-50%,-50%)',
      ' -ms-transform': 'translate(-50%,-50%)',
      '-o-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    },
  },
  counterAmountInput: {
    height: 30,
    flex: '0 0 54px',
    width: 54,
    marginRight: 2,
    '& input': {
      width: '100%',
      border: 0,
      '-webkit-box-shadow': 'inset 0 2px 5px rgb(0 0 0 / 10%)',
      boxShadow: 'inset 0 2px 5px rgb(0 0 0 / 10%)',
      bordeRadius: 2,
      backgroundColor: '#f1f1f1',
      color: '#232323',
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'center',
      height: '100%',
      padding: 0,
      paddingLeft: 0,
    },
  },
  counterPlus: {
    flex: '0 0 30px',
    width: 30,
    height: 30,
    marginRight: 2,
    backgroundColor: '#ececec',
    borderRadius: 2,
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 15,
      height: 3,
      backgroundColor: '#868686',
      '-webkit-transform': 'translate(-50%,-50%)',
      ' -ms-transform': 'translate(-50%,-50%)',
      '-o-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 3,
      height: 15,
      backgroundColor: '#868686',
      '-webkit-transform': 'translate(-50%,-50%)',
      ' -ms-transform': 'translate(-50%,-50%)',
      '-o-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
    },
  },
  tableToplineItemTotal: {
    flex: '0 0 170px',
    width: 170,
    maxWidth: 170,
  },
  tableToplineItemDeleteButton: {
    flex: '0 0 70px',
    width: 70,
    maxWidth: 70,
  },
  itemContainer: {
    flexWrap: 'nowrap',
    padding: '20px',
    color: theme.palette.primary.itemTextColor,
    '&:last-child': { marginBottom: 40 },
  },
  cartItemPicture: {
    flex: '0 0 170px',
    width: 170,
    maxWidth: 170,
    marginRight: 50,
    fontSize: 0,
    lineHeight: 0,
    '& img': {
      objectFit: 'contain',
      maxWidth: 170,
      maxHeight: 170,
      width: '100%',
      height: '100%',
    },
  },
  cartItemText: {
    flex: '1 1 auto',
    '& span': {
      fontSize: 22,
      lineHeight: '24px',
      letterSpacing: '1px',
      fontWeight: 600,
      cursor: 'pointer',
      color: theme.palette.primary.itemTextColor,
      transition: 'color 0.25s',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  cartItemPrice: {
    fontWeight: 600,
    flex: '0 0 180px',
    width: 180,
    maxWidth: 180,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: '36px',
  },
  emptyRow: {
    flex: '0 0 140px',
    width: 140,
    maxWidth: 140,
  },
  cartItemAmount: {
    flex: '0 0 140px',
    width: 140,
    maxWidth: 140,
    textAlign: 'center',
  },
  cartItemTotal: {
    fontWeight: 600,
    flex: '0 0 180px',
    width: 180,
    maxWidth: 180,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: '36px',
  },
  deleteButton: {
    padding: '.375rem .75rem',
    color: theme.palette.primary.main,
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: 'transparent',
    fontSize: '1rem',
    lineHeight: 1.5,
    borderRadius: '.25rem',
    cursor: 'pointer',
    border: `2px solid ${theme.palette.primary.main}`,
    transition: 'all 0.3s',
    '&:hover': {
      border: '2px solid transparent',
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dividerRootHorizontal: {
    margin: '20px 0',
    background: 'black',
    boxShadow: '0 2.3px 0 rgb(255 255 255 / 5%)',
    height: 2,
    width: '100%',
  },
  cartTextHeader: {
    flex: '0 0 200px',
    fontSize: '1.7rem',
    '& h1': { margin: 0 },
  },
  makeOrderContainer: {
    backgroundColor: theme.palette.primary.linen,
    padding: 20,
    // marginBottom: 50,
    // flex: "0 0 500px",
    alignItems: 'center',
    width: '100%',
  },
  fixedWidthContainer: {
    width: '75%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media screen and (max-width: 1300px) ': {
      width: '85%',
    },
    '@media screen and (max-width: 930px) ': {
      width: '95%',
    },
  },

  applyPromocodeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  makeOrderButton: {
    height: 70,
    width: 250,
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: 'none',
    borderRadius: '.35rem',
    fontFamily: theme.defaultFont,
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: 25,
    padding: '0px 20px',
    letterSpacing: '1.5px',
    '&:disabled': {
      backgroundColor: theme.palette.primary.darkGreyText,
    },
  },
  applyPromocodeButton: {
    height: 70,
    width: 250,
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: 'none',
    borderRadius: '.35rem',
    fontFamily: theme.defaultFont,
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: 25,
    padding: '0px 20px',
    letterSpacing: '1.5px',
    '&:disabled': {
      backgroundColor: theme.palette.primary.darkGreyText,
    },
  },
  goToMenuButton: {
    height: 80,
    width: 'max-content',
    borderRadius: '.35rem',
    border: 'none',
    padding: '0px 20px',
    letterSpacing: '1.5px',
    fontFamily: theme.defaultFont,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
    '& a:-webkit-any-link': {
      color: 'white',
      fontSize: 30,
      textDecoration: 'none',
    },
  },

  // MakeOrderPage
  createOrderContainer: {
    // background: "#ffff",
    position: 'relative',
    overflow: 'hidden',
    paddingTop: 50,
    backgroundRepeat: 'repeat',
    width: '100%',
    transition: 'all 0.4s step-end',
    // minHeight: "calc(100vh - 200px)",
    backgroundImage: `url(${modalBackground})`,
  },
  orderTextHeader: {
    // flex: "0 0 100%",
    fontSize: '1.7rem',
    '& h1': { margin: 0 },
  },
  formContainer: {
    width: '100%',
    padding: '20px 0px',
  },
  requiredFiledsError: {
    width: '100%',
    textAlign: 'right',
    '& p': {
      fontSize: 21,
      color: theme.palette.primary.activeRed,
      fontWeight: 900,
    },
  },
  orderModalButtonContainer: {
    alignSelf: 'flex-end',
  },
  paperStyle: {
    backgroundImage: `url(${modalBackground})`,
    fontFamily: theme.defaultFont,
    borderRadius: 14,
    '& h1': { textAlign: 'center' },
    '& h2': { fontSize: 25, marginBottom: 0 },
    '& label': { fontWeight: 700, fontSize: 25 },
    '& .MuiGrid-item': {
      paddingBottom: 10,

      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    '& label + .MuiInput-formControl': {
      marginTop: 30,
    },
    '& .MuiTypography-body1': {
      fontFamily: theme.defaultFont,
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1,
    },
  },
  rowTitle: {
    flex: '1 1 100%',
  },
  inputLabel: {
    fontFamily: theme.defaultFont,
    fontSize: 25,
    color: 'black',
    width: 'max-content',
  },
  shortInput: {
    width: '35%',
  },
  formControlLabelRoot: {
    justifyContent: 'flex-end',
  },
  formTextFieldRoot: {
    width: '100%',
  },
  phoneTextField: {
    width: '100%',
  },
  firstRow: {
    alignItems: 'center',
    width: '100%',
  },
  secondRow: {
    alignItems: 'baseline',
    width: '100%',
  },
  maxWidthStyles: {
    maxWidth: 'calc(100vw - 30%)',
    width: '100%',
    '@media screen and (max-width: 1550px)': {
      maxWidth: 'calc(100vw - 20%)',
    },
    '@media screen and (max-width: 750px)': {},
  },
  thirdRow: {
    alignItems: 'center',
    width: '100%',
  },
  paymentBlock: { width: '30%' },

  rootCommentsField: { width: '100%' },
  commentsBlock: { width: '60%' },
  promocodeBlock: { width: '60%', alignSelf: 'flex-end' },
  makeOrderButtonContainer: {
    alignSelf: 'flex-end',
    width: '28%',
  },
  phoneTextFieldContainer: { width: '45%' },
  loadingCircle: {
    position: 'absolute',
    top: '55%',
    left: '25%',
    right: '25%',
  },
  formFieldContainer: {
    paddingTop: 20,
  },
  streetNameFieldContainer: {
    width: '45%',
    paddingTop: 20,
  },
  emailFieldContainer: {
    width: '45%',
    paddingTop: 20,
  },
  houseFlatRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  pickUpTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  pickUpTimeRadioGroup: {
    width: '50%',
  },
  pickUpTimeInTimeContainer: {
    width: '50%',
  },

  // responsive styles
  '@media screen and (max-width: 900px)': {
    container: {
      paddingTop: 20,
    },
    maxWidthStyles: {
      maxWidth: 'calc(100vw - 8%)',
    },
  },
  '@media screen and (max-width: 770px)': {
    streetNameFieldContainer: {
      width: '90%',
    },
    phoneTextFieldContainer: {
      paddingTop: 20,
      width: '90%',
    },
    emailFieldContainer: {
      width: '90%',
    },
    thirdRow: {
      alignItems: 'unset',
    },
    makeOrderButtonContainer: {
      width: '100%',
      textAlign: 'end',
    },
    commentsBlock: {
      width: '90%',
    },
    promocodeBlock: {
      alignSelf: 'unset',
      width: '90%',
    },
    paymentBlock: {
      width: '90%',
    },
    requiredFiledsError: {
      width: '90%',
    },
    pickUpTimeContainer: {
      width: '90%',
    },
  },
  '@media screen and (max-width: 700px)': {
    createOrderContainer: {
      paddingTop: 0,
    },
    formContainer: {
      padding: 20,
      width: '100%',
    },
    firstRow: {
      alignItems: 'unset',
      flexDirection: 'column',
    },
    secondRow: {
      flexDirection: 'column',
    },
    thirdRow: {
      flexDirection: 'column',
    },
    maxWidthStyles: {
      maxWidth: '100vw',
    },
  },
  '@media screen and (max-width: 640px)': {
    pickUpTimeContainer: {
      flexDirection: 'column',
      alignItems: 'unset',

    },
    pickUpTimeRadioGroup: {
      width: '100%',
    },
    pickUpTimeInTimeContainer: {
      paddingTop: 20,
      width: '100%',
    },
  },
  //--

  // responsive styles
  '@media screen and (max-width: 930px)': {
    makeOrderContainer: { flexDirection: 'column', alignItems: 'unset' },
  },
  '@media screen and (max-width: 1020px)': {
    makeOrderButton: {
      height: 70,
    },
    cartItemsContainer: { width: '100%' },
    tableContainer: {
      display: 'none',
    },
    itemContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    cartItemPicture: {
      flex: '0 0 100px',
      margin: '0 auto 20px',
      width: 300,
      maxWidth: 300,
      '& img': {
        maxHeight: 300,
        maxWidth: 300,
      },
    },
    cartItemText: {
      textAlign: 'center',
      margin: '0 auto 20px',
      maxWidth: 500,
      width: '100%',
      fontSize: 25,
    },
    cartItemPrice: {
      ...itemResponsiveStyles,
    },
    emptyRow: {
      margin: '0 auto 25px',
      flex: '1 1 auto',
      maxWidth: 500,
      width: '100%',
    },
    cartItemAmount: { ...itemResponsiveStyles },
    cartItemTotal: {
      ...itemResponsiveStyles,
    },
    deleteButtonContainer: {
      ...itemResponsiveStyles,
    },
  },
  '@media screen and (max-width: 550px)': {
    makeOrderButton: {
      alignSelf: 'center',
    },
    contentContainer: {
      paddingTop: 0,
    },
    infoContainer: {
      width: '100%',
    },
    makeOrderContainer: {
      flexDirection: 'column',
      '& h2': {
        fontSize: 20,
        textAlign: 'left',
      },
    },
    dialogContent: {
      '& img': {
        maxWidth: '100vw',
        width: '100%',
      },
      '& h1': {
        fontSize: 17,
      },
      '& p': {
        fontSize: 15,
      },
    },
  },
  '@media screen and (max-width: 464px)': {
    cartTextHeader: {
      flex: '0 0 100%',
      textAlign: 'center',
    },
    orderTextHeader: {
      textAlign: 'center',
    },
    makeOrderContainer: {
      maxWidth: '100%',
    },
  },
});
