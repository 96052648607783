import React from 'react'
import { makeStyles } from '@material-ui/core'

import { lambdaImage } from '../../../assets'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  heading: {
    marginTop: 0,
    marginBottom: 20,
    fontSize: '2.8rem',
    letterSpacing: '3px',
    fontFamily: theme.headingFont,
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100%',
    textAlign: 'center',
    color: theme.palette.primary.itemTextColor,
    '& span': {
      display: 'inline-block',
      position: ' relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: '100%',
        margin: '-5px 72px 0 0',
        height: '9px',
        width: '84px',
        background: `url(${lambdaImage}) no-repeat left top`,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '100%',
        margin: '-5px 0 0 72px',
        height: '9px',
        width: '84px',
        background: `url(${lambdaImage}) no-repeat right top`,
      },
    },
  },
  subText: {
    textAlign: 'center',
    color: theme.palette.primary.brown,
    marginBottom: 0,
    fontSize: ' 1.8rem',
  },
  '@media screen and (max-width: 670px)': {
    container: {
      width: '100%',
    },
  },
  '@media screen and (max-width: 520px)': {
    heading: {
      '& span': {
        '&:before': {
          margin: '-5px 40px 0 0',
        },
        '&:after': {
          margin: '-5px 0 0 40px',
        },
      },
    },
  },
}))

function MenuCategoryText({ text }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>
        <span>{text}</span>
      </h2>
    </div>
  )
}

export default MenuCategoryText
