import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => {
  const tabStyles = {
    cursor: 'pointer',
    margin: '0 5px 20px 5px',
    backgroundColor: theme.palette.primary.translucentLightBackground,
    border: `2px solid ${theme.palette.primary.darkGreen}`,
    color: theme.palette.primary.brown,
    borderRadius: '25px',
    transition: '.3s',
    '& div': {
      height: '53px',
      paddingLeft: '25px',
      paddingRight: '25px',
      display: 'flex',
      msFlexPack: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      msFlexAlign: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      fontFamily: theme.defaultFont,
      fontSize: '1.8rem',
      transition: '.1s',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderColor: theme.palette.primary.main,
    },
  }
  return {
    menuTabs: {
      borderBottom: props =>
        props.noBorder
          ? 'none'
          : `2px solid ${theme.palette.primary.darkGreen}`,
      margin: props => props.noBorder ? '44px 0px 0px 0px' : '44px 0px',
      listStyle: 'none',
      display: 'flex',
      msFlexPack: 'justify',
      justifyContent: 'center',
      msFlexWrap: 'wrap',
      flexWrap: 'wrap',
      padding: '0 50px',
      '& li:first-child': {
        marginRight: props => (props.justifySpaceBetween ? '10px' : ''),
      },
    },
    tab: tabStyles,
    activeTab: {
      ...tabStyles,
      color: 'white',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '@media screen and (max-width: 820px)': {
      menuTabs: {
        '& li:first-child': {
          marginRight: '0px !important',
        },
      },
    },
    '@media screen and (max-width: 520px)': {
      menuTabs: {
        padding: 0,
      },
    },
  }
})

function MenuTabs(props) {
  const classes = useStyles(props)
  const { onChange, value, tabs, justifySpaceBetween } = props
  return (
    <ul
      className={classes.menuTabs}
      //style={justifySpaceBetween ? { justifyContent: justifySpaceBetween } : {}}
      id="menu-tabs"
    >
      {tabs.map(el => (
        <li
          className={el.value === value ? classes.activeTab : classes.tab}
          key={`tab-${el.id}`}
        >
          <div onClick={() => onChange(el.value)}>{el.label}</div>
        </li>
      ))}
    </ul>
  )
}
export default MenuTabs
