import './App.css'

import React from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Header from './components/Header'
import HeaderSlideShow from './components/HeaderSlideShow'
import { MenuPageProvider } from './context/MenuPageContext/context'
import muiTheme from './styles/theme'
import AboutUsPage from './views/AboutUsPage'
import VK49865Page from './views/VK49865'
import CartPage from './views/CartPage'
import LandingPage from './views/LandingPage'
import OfferPage from './views/OfferPage'
import PaymentAndShippingPage from './views/PaymentAndShippingPage'
import PoliticsPage from './views/PoliticsPage'

function App() {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Router>
        <MenuPageProvider>
          <Header />
          <HeaderSlideShow />
          <Route component={LandingPage} path="/" exact />
          <Route component={CartPage} path="/cart" />
        </MenuPageProvider>
        <Route component={AboutUsPage} path="/about" />
        <Route component={VK49865Page} path="/vk49865" />
        <Route
          component={PaymentAndShippingPage}
          path="/shipping-and-payment"
        />
        <Route component={OfferPage} path="/offer" />
        <Route component={PoliticsPage} path="/politics" />
      </Router>
    </MuiThemeProvider>
  )
}

export default App
