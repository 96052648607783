import React from "react";
import truncate from "lodash/truncate"

function DescriptionBlock({ classes, description, onOpenModal }) {
  return (
    <div className={classes.descriptionBlock} onClick={onOpenModal}>
      <p>
        {truncate(description, {
          length: 50,
          omission: '..'
        })} &nbsp;
        <span className={classes.descriptionActive} onClick={onOpenModal}>
          подробнее..
        </span>
      </p>
    </div>
  );
}

export default DescriptionBlock;
