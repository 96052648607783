const WEIGHT_HASH = {
  KG: 'кг.',
  SM: 'см.',
  L: 'л.',
}

const MENU_CATEGORY_HASH = {
  NEW: 'new',
  POPULAR: 'popular',
  PIES: 'pies',
  PIZZA: 'pizza',
  SEMIMANUFACTURES: 'semimanufactures',
  PROMOTION: 'promotion',
  DRINKS: 'drinks',
  
}

const SUB_CATEGORIES_HASH = {
  STANDARD_PIES: 'standard',
  KISH: 'kish',
  FISH_PIES: 'fishPies',
  OSSETIAN_PIES: 'ossetianPies',
  SWEET_PIES: 'sweetPies',
  DUMPLING: 'dumplings',
  OTHERS: 'others',
  MANTI: 'manti',
  VARENIKI: 'vareniki',
  PUFF: 'puff',
  SETS: 'sets',
  NEW_PIES: 'newpies',
  NEW_PIZZA: 'newpizza'
}

const PROMOTION = {
  MORS: 'mors',
  COMPOTE: 'compote',
  RASPBERRIES_PIE: 'raspberriesPie',
}

const ADDITIONAL_MORS = {
  count: 1,
  description: 'Морс в ПОДАРОК',
  menuCategory: MENU_CATEGORY_HASH.PROMOTION,
  price: 0,
  subMenuCategory: '',
  title: 'Морс 1 литр в ПОДАРОК',
  total: 0,
  value: PROMOTION.MORS,
  weight: 0,
}

const ADDITIONAL_COMPOTE = {
  count: 1,
  description: 'Компот в ПОДАРОК',
  menuCategory: MENU_CATEGORY_HASH.PROMOTION,
  price: 0,
  subMenuCategory: '',
  title: 'Компот 1 литр в ПОДАРОК',
  total: 0,
  value: PROMOTION.COMPOTE,
  weight: 0,
}

const ADDITIONAL_RASPBERRIES_PIE = {
  subMenuCategory: '',
  menuCategory: MENU_CATEGORY_HASH.PROMOTION,
  count: 1,
  description: 'Пирог с малиной в ПОДАРОК',
  price: 0,
  selectedVariants: {
    dough: [{ label: 'Дрожжевое тесто', value: 'yeast' }],
  },
  title: 'Пирог с малиной в ПОДАРОК',
  total: 0,
  totalOldPrice: 0,
  value: PROMOTION.RASPBERRIES_PIE,
  variants: {
    dough: [
      { value: 'yeast', label: 'Дрожжевое тесто' },
      { value: 'sourCream', label: 'Сметанное тесто' },
      { value: 'puff', label: 'Слоеное тесто' },
    ],
  },
  weight: 1,
}

export {
  ADDITIONAL_COMPOTE,
  ADDITIONAL_MORS,
  ADDITIONAL_RASPBERRIES_PIE,
  MENU_CATEGORY_HASH,
  PROMOTION,
  SUB_CATEGORIES_HASH,
  WEIGHT_HASH,
}
