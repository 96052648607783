import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import GoogleMap from './Components/YNMap'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 100px)',
    background: '#ffff',
  },
  highlightedText: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  requisites: {
    fontSize: '1rem',
  },
  content: {
    width: '75%',
    margin: '0 auto',
    padding: '40px 0px',
  },
  descriptionBlock: {
    '& p': {
      color: 'black',
      fontSize: '2rem',
      '& span': {
        fontWeight: 'bold',
      },
    },
    ' & h2': {
      fontSize: '2.2rem',
      color: theme.palette.primary.textColor,
      marginTop: 0,
    },
  },
}))

const AboutUsPage = () => {
  const classes = useStyles()

  // const handlePay = useCallback(async () => {
  //   try {
  //     // await fetch('https://3dsec.sberbank.ru/sbrfpayment/rest/register.do', {
  //     //   method: 'post',
  //     //   headers: {
  //     //     'Access-Control-Allow-Origin': '*',
  //     //     'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE'
  //     //   },
  //     //   body: {
  //     //     "amount": "123",
  //     //     "userName": "t860213816160_340922-api",
  //     //     "password": "=tlmNNGuw",
  //     //   }
  //     // })
  //     //   .then(response => response.json())
  //     //   .then(data => console.log(data))

  //     await axios({
  //       url: `/api/pay-order`,
  //       data: {
  //         userName: "t860213816160_340922-api",
  //         password: "tlmNNGuw",
  //         amount: "123",
  //         orderId: 1,
  //         returnUrl: "https://obojayka86.ru/",
  //       },
  //     }).then((resp) => {
  //       console.log(resp.data, ",_resp.data");
  //       if (resp.data.success) {
  //         console.log("SUCCESS");
  //       } else window.alert(`${resp.data.message}`);
  //     });
  //   } catch (error) {
  //     console.log(error, "handlePay ERROR");
  //   }
  // }, []);

  return (
    <div className={classes.container}>
      <Grid alignItems="center" direction="column" className={classes.content}>
        <Grid item className={classes.descriptionBlock}>
          <h2>МЫ НАХОДИМСЯ:</h2>
          <p>г. Сургут, пр. Набережный 2/1 на Энтузиастов 40 (вход в Сабвэй)</p>
          <GoogleMap />
          <p>Работаем ежедневно с 08:00 до 20:00</p>
          <p>
            <a className={classes.highlightedText} href="tel:+73462960001">
              <span className={classes.highlightedText}>
                {' '}
                +7 (3462) 64-00-05{' '}
              </span>
            </a>
          </p>
          <p>
            Подписывайтесь на нас в Вконтакте: акции, скидки и много
            интересного!{' '}
            <a
              className={classes.highlightedText}
              href="https://vk.com/obojayka86"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={classes.highlightedText}>
                https://vk.com/obojayka86{' '}
              </span>
            </a>
          </p>
          <p>
            +7 (922) <span className={classes.highlightedText}> 425-90-05</span>
          </p>
          <p> Пироги по-домашнему! Готовим с любовью</p>
        </Grid>
      </Grid>
    </div>
  )
}
export default AboutUsPage
