import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import map from 'lodash/map'

import { MenuPageContext } from '../../../context/MenuPageContext/context'
import {
  MENU_CATEGORY_HASH,
  SUB_CATEGORIES_HASH,
  PROMOTION,
  WEIGHT_HASH,
} from '../../../const/menu'

function CartItemInfo({ setOpenModalWindow, setModalWindowInfo, classes }) {
  const { removeItemFromCart, cartItems, setItemAmountToCart } =
    useContext(MenuPageContext)

  const itemWeight = ({ weight, menuCategory, subMenuCategory }) => {
    if (
      menuCategory === MENU_CATEGORY_HASH.PIZZA ||
      subMenuCategory === SUB_CATEGORIES_HASH.NEW_PIZZA
    ) {
      return weight === 1.185 ? 40 : 35
    }

    if (menuCategory === MENU_CATEGORY_HASH.DRINKS) {
      return weight === 1.808 ? 1 : 0.5
    }

    if (
      menuCategory === PROMOTION.MORS ||
      subMenuCategory === SUB_CATEGORIES_HASH.PUFF
    ) {
      return ''
    }

    return weight
  }

  const itemWeightText = ({ menuCategory, subMenuCategory }) => {
    if (
      menuCategory === PROMOTION.MORS ||
      subMenuCategory === SUB_CATEGORIES_HASH.PUFF
    )
      return ''
    else if (menuCategory === MENU_CATEGORY_HASH.DRINKS) {
      return WEIGHT_HASH.L
    }
    return menuCategory === MENU_CATEGORY_HASH.PIZZA
      ? WEIGHT_HASH.SM
      : WEIGHT_HASH.KG
  }

  function renderVariants(selectedVariants) {
    if (!selectedVariants) return ''

    const test = map(selectedVariants, el => {
      if (!el[0]) return ['']
      return el[0].label
    })

    return test.join()
  }

  return cartItems.map((item, index) => {
    return (
      <Grid
        container
        direction="row"
        key={`cart-item-${index}`}
        className={classes.itemContainer}
        alignItems="center"
      >
        <Grid
          item
          className={classes.cartItemPicture}
          onClick={() => setModalWindowInfo(item) || setOpenModalWindow(true)}
        >
          <img
            alt="menu-item"
            style={{ width: '100%' }}
            src={
              process.env.PUBLIC_URL +
              `/productPhoto/${item.menuCategory}/${item.value}.jpg`
            }
          />
        </Grid>
        <Grid item className={classes.cartItemText}>
          <span
            onClick={() => setModalWindowInfo(item) || setOpenModalWindow(true)}
          >
            {item.menuCategory === MENU_CATEGORY_HASH.PROMOTION ||
            item.subMenuCategory === SUB_CATEGORIES_HASH.SETS
              ? item.title
              : `${item.title}, ${renderVariants(item.selectedVariants)} ${itemWeight(item)} ${itemWeightText(item)}`}
          </span>
        </Grid>
        <Grid item data-name="Цена" className={classes.cartItemPrice}>
          <span>{item.price} руб.</span>
        </Grid>
        {item.menuCategory === MENU_CATEGORY_HASH.PROMOTION ? (
          <div className={classes.emptyRow}></div>
        ) : (
          <Grid data-name="Кол-во" item className={classes.cartItemAmount}>
            <div className={classes.counterContainer}>
              <div
                onClick={() => {
                  if (item.count === 1 || item.count === item.minCount) return

                  setItemAmountToCart({
                    ...item,
                    count: --item.count,
                  })
                }}
                className={classes.counterMinus}
              ></div>
              <div className={classes.counterAmountInput}>
                <input disabled value={item.count} type="number" />
              </div>
              <div
                onClick={() =>
                  setItemAmountToCart({
                    ...item,
                    count: ++item.count,
                  })
                }
                className={classes.counterPlus}
              ></div>
            </div>
          </Grid>
        )}
        <Grid data-name="Итого" item className={classes.cartItemTotal}>
          {Math.ceil(item.price * item.weight * item.count)} руб.
        </Grid>
        <Grid
          data-name="Удалить"
          item
          className={classes.deleteButtonContainer}
        >
          <button
            className={classes.deleteButton}
            onClick={() => removeItemFromCart(item)}
          >
            x
          </button>
        </Grid>
      </Grid>
    )
  })
}

export default CartItemInfo
