import React, { useContext, useState } from 'react'
import { Grid, withStyles, Divider } from '@material-ui/core'
import { HideOn } from 'react-hide-on-scroll'
import EjectIcon from '@material-ui/icons/Eject'

import { MenuPageContext } from '../../context/MenuPageContext/context'
import { styles } from './styles'
import ProductInfoModalWindow from '../../components/ProductInfoModalWindow'
import MakeOrderBlock from './Components/MakeOrderBlock'
import CartItemInfo from './Components/CartItemInfo'
import { CommonLoading } from 'react-loadingg'

const Cart = ({ classes }) => {
  const [responseBE, setResponse] = useState(null)
  const [loading, setLoading] = useState(false)
  const [emptyCartText, setEmptyCartText] = useState(
    'Ваша корзина пуста! Скорее добавьте в нее что-нибудь вкусное :)',
  )
  const [modalWindowInfo, setModalWindowInfo] = useState(null)
  const [openModalWindow, setOpenModalWindow] = useState(false)

  const { cartItems } = useContext(MenuPageContext)

  const handleScrollToTop = () => {
    const el = document.getElementById('cartPage-container')
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  if (loading) {
    return (
      <div className={classes.container} style={{ height: 300 }}>
        <div className={classes.loadingCircle}>
          <CommonLoading color="red" speed={1.4} />
        </div>
      </div>
    )
  }

  if (responseBE) {
    return (
      <div className={classes.container}>
        <h1 style={{ padding: '0px 20px' }}>{responseBE}</h1>
      </div>
    )
  }

  return (
    <div id="cartPage-container">
      {cartItems.length ? (
        <>
          <ProductInfoModalWindow
            openModalWindow={openModalWindow}
            setOpenModalWindow={setOpenModalWindow}
            modalWindowInfo={modalWindowInfo}
            classes={classes}
          />
          <div className={classes.container}>
            <Grid container className={classes.contentContainer}>
              <Grid item container direction="column">
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item className={classes.cartTextHeader}>
                    <h1>Корзина</h1>
                  </Grid>
                </Grid>
                <div className={classes.infoContainer}>
                  <p>
                    При заказе до 1000 рублей стоимость доставки по городу
                    <span className={classes.activeRedText}> 250 руб. </span>
                    от 1000 рублей до 3000 стоимость доставки{' '}
                    <span className={classes.activeRedText}> 150 руб. </span>
                    От 3000 рублей доставка{' '}
                    <span className={classes.activeRedText}> БЕСПЛАТНАЯ. </span>
                    ГРЭС, Усольцева, Билецкого{' '}
                    <span className={classes.activeRedText}>+50 рублей </span> к
                    стоимости доставки. эропорт, Белый Яр, Барсово, Солнечный{' '}
                    <span className={classes.activeRedText}>+100 руб.</span>{' '}
                  </p>
                </div>
              </Grid>
              <Divider classes={{ root: classes.dividerRootHorizontal }} />
              <div className={classes.cartItemsContainer}>
                <Grid
                  container
                  className={classes.tableContainer}
                  alignItems="center"
                >
                  <Grid item className={classes.tableToplineItem}>
                    Наименование
                  </Grid>
                  <Grid item className={classes.tableToplineItemPrice}>
                    Цена
                  </Grid>
                  <Grid item className={classes.tableToplineItemAmount}>
                    Количество
                  </Grid>
                  <Grid item className={classes.tableToplineItemTotal}>
                    Итого
                  </Grid>
                  <Grid
                    item
                    className={classes.tableToplineItemDeleteButton}
                  ></Grid>
                </Grid>
                <CartItemInfo
                  {...{
                    setOpenModalWindow,
                    setModalWindowInfo,
                    classes,
                  }}
                />
              </div>
            </Grid>
          </div>
          <MakeOrderBlock
            onChangeLoading={value => {
              setLoading(value)
            }}
            onChangeResponse={value => {
              setResponse(value)
            }}
          />
        </>
      ) : (
        <div className={classes.container}>
          <div className={classes.backgroundImage}></div>
          <Grid
            container
            className={classes.contentContainer}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <h1>{emptyCartText}</h1>
            <button className={classes.goToMenuButton}>
              <a href="/">Перейти в меню</a>
            </button>
          </Grid>
        </div>
      )}
      <HideOn inverse divID="cartPage-container">
        <div onClick={handleScrollToTop} className={classes.buttonScrollToTop}>
          <EjectIcon />
        </div>
      </HideOn>
    </div>
  )
}
export default withStyles(styles, { withTheme: true })(Cart)
