export const SET_MENU_SUB_CATEGORY = 'SET_MENU_SUB_CATEGORY';
export const SET_MENU_CATEGORY = 'SET_MENU_CATEGORY';
export const SET_ITEM_WEIGHT = 'SET_ITEM_WEIGHT';
export const SET_ITEM_VARIANTS = 'SET_ITEM_VARIANTS';
export const SET_ITEM_AMOUNT_TO_CART = 'SET_ITEM_AMOUNT_TO_CART';
export const SET_CART_FROM_LOCAL_STORAGE = 'SET_CART_FROM_LOCAL_STORAGE';
export const SET_ERROR = 'SET_ERROR';

export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const FETCH_ITEMS_BY_CATEGORY = 'FETCH_ITEMS_BY_CATEGORY';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
