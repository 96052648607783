import React, { useContext, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import EjectIcon from '@material-ui/icons/Eject'
import { HideOn } from 'react-hide-on-scroll'
import { CircleToBlockLoading } from 'react-loadingg'

import { MENU_CATEGORY_HASH } from '../../const/menu'
import { MenuPageContext } from '../../context/MenuPageContext/context'
import getTextTranslate from '../../utils/getTextTranslate'
import ContentMenu from '../MenuProducts'
import MenuCategoryText from './Components/MenuCategoryText'
import MenuTabs from './Components/MenuTabs'
import { listOfSubCategories } from './mocSubCategories'
import { styles } from './styles'

const MENU_CATEGORIES = [
  { label: 'Новинки', value: MENU_CATEGORY_HASH.NEW, id: 0 },
  { label: 'Популярное', value: MENU_CATEGORY_HASH.POPULAR, id: 1 },
  { label: 'Пироги', value: MENU_CATEGORY_HASH.PIES, id: 2 },
  { label: 'Пицца', value: MENU_CATEGORY_HASH.PIZZA, id: 3 },
  { label: 'Полуфабрикаты', value: MENU_CATEGORY_HASH.SEMIMANUFACTURES, id: 4 },
  { label: 'Напитки', value: MENU_CATEGORY_HASH.DRINKS, id: 5 },
]

function LandingPage({ classes }) {
  const {
    fetchItems,
    setSubMenuCategory,
    subMenuCategory,
    setMenuCategory,
    menuCategory,
    loading,
  } = useContext(MenuPageContext)

  const handleChangeMenu = menu => {
    setMenuCategory(menu)
  }

  const handleChangeSubMenuCategory = value => {
    setSubMenuCategory(value)
  }

  useEffect(() => {
    fetchItems()
  }, [subMenuCategory, menuCategory])

  const scrollToTop = () => {
    const el = document.getElementById('menu-tabs')
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className={classes.container} id="landingPage-container">
      <Grid
        className={classes.contentContainer}
        direction="column"
        justify="space-between"
        container
      >
        <MenuTabs
          justifySpaceBetween="space-between"
          tabs={MENU_CATEGORIES}
          value={menuCategory}
          onChange={handleChangeMenu}
        />
        {loading ? (
          <div className={classes.loadingCircle}>
            <CircleToBlockLoading color="red" speed={1.6} />
          </div>
        ) : (
          <>
            <MenuCategoryText
              text={getTextTranslate('menuCategories', menuCategory)}
            />

            {subMenuCategory?.length > 0 &&
              listOfSubCategories[menuCategory].length > 0 && (
                <MenuTabs
                  tabs={listOfSubCategories[menuCategory]}
                  value={subMenuCategory}
                  onChange={handleChangeSubMenuCategory}
                />
              )}
            <Grid justify="space-between" container>
              <ContentMenu />
            </Grid>
          </>
        )}
      </Grid>

      <HideOn divID="landingPage-container" inverse>
        <div className={classes.buttonScrollToTop} onClick={scrollToTop}>
          <EjectIcon />
        </div>
      </HideOn>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(LandingPage)
