import { SUB_CATEGORIES_HASH } from '../../const/menu';

export const listOfSubCategories = {
  new: [
    { label: 'Пироги', value: SUB_CATEGORIES_HASH.NEW_PIES, id: 0 },
    { label: 'Пицца', value: SUB_CATEGORIES_HASH.NEW_PIZZA, id: 1 },
  ],
  pies: [
    { label: 'Сытные', value: SUB_CATEGORIES_HASH.STANDARD_PIES, id: 0 },
    { label: 'Рыбные Пироги', value: SUB_CATEGORIES_HASH.FISH_PIES, id: 1 },
    { label: 'Осетинские Пироги', value: SUB_CATEGORIES_HASH.OSSETIAN_PIES, id: 2 },
    { label: 'Сладкие Пироги', value: SUB_CATEGORIES_HASH.SWEET_PIES, id: 3 },
    { label: 'КИШ', value: SUB_CATEGORIES_HASH.KISH, id: 4 },
    { label: 'Пирожки', value: SUB_CATEGORIES_HASH.PUFF, id: 5 },
    { label: 'Сеты', value: SUB_CATEGORIES_HASH.SETS, id: 6 },
  ],
  semimanufactures: [
    { label: 'Манты', value: SUB_CATEGORIES_HASH.MANTI, id: 0 },
    { label: 'Пельмени', value: SUB_CATEGORIES_HASH.DUMPLING, id: 1 },
    { label: 'Вареники', value: SUB_CATEGORIES_HASH.VARENIKI, id: 2 },
  ],
  pizza: [],
  drinks: [],
};
