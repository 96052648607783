import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Divider } from '@material-ui/core'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import CallOutlinedIcon from '@material-ui/icons/CallOutlined'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { logoImage } from '../../assets'

import MobileHeader from './MobileHeader'
import { MenuPageContext } from '../../context/MenuPageContext/context'
import useStyles from './styles'

const Header = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { changePathHandle, cartItems, setCartItemsFromLocalStorage } =
    useContext(MenuPageContext)

  useEffect(() => {
    if (localStorage.getItem('cart')) setCartItemsFromLocalStorage()
  }, [])

  const scrollToMenu = () => {
    const el = document.getElementById('menu-tabs')
    if (el) el.scrollIntoView({ behavior: 'smooth' })
    changePathHandle('/')
  }

  return (
    <>
      <div className={classes.headerContainer} id="headerContainer">
        <MobileHeader classes={classes} />

        <div className={classes.headerBlock}>
          <Link to="/" className={classes.headerListName}>
            <img width={70} height={70} src={logoImage} />
            Обожайкин
          </Link>
          <ul className={classes.headerList}>
            <li
              className={cn(classes.listBlock, {
                [classes.activeHeaderLink]: pathname === '/',
              })}
              onClick={scrollToMenu}
            >
              <RestaurantMenuIcon fontSize="large" />
              <span> Меню</span>
            </li>
            <Divider classes={{ root: classes.dividerRootVertical }} />
            <li
              className={cn(classes.listBlock, {
                [classes.activeHeaderLink]: pathname === '/about',
              })}
            >
              <Link to="/about">
                <InfoOutlinedIcon fontSize="large" />
                <span>Контакты</span>
              </Link>
            </li>
            <Divider classes={{ root: classes.dividerRootVertical }} />
            <li
              className={cn(classes.listBlock, {
                [classes.activeHeaderLink]:
                  pathname === '/shipping-and-payment',
              })}
            >
              <Link to="/shipping-and-payment">
                <LocalShippingOutlinedIcon fontSize="large" />
                <span> Доставка и оплата</span>
              </Link>
            </li>
            <li className={classes.phoneBlock}>
              <CallOutlinedIcon
                fontSize="large"
                className={classes.phoneIcon}
                color="primary"
              />
              <div className={classes.phonesList}>
                <p>
                  <a href="tel:+73462640005">
                    <span className={classes.phoneText}>
                      +7 (3462) 64-00-05
                    </span>
                  </a>
                </p>
                <p>
                  +7 (922){' '}
                  <span className={classes.phoneText}> 425-90-05 </span>
                </p>
              </div>
            </li>
            <li className={classes.cartBlockHeader}>
              <Link to="/cart">
                <ShoppingCartOutlinedIcon fontSize="large" color="primary" />
                <span className={classes.iconCount}>{cartItems.length}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
export default Header
