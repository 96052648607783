import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    map: {
        border: "1px solid;",
        width: "100%",
        height: "400px",

    },
}));

function YNMap() {
    const classes = useStyles();
    return (
        <div className={classes.map}>
            <YMaps>
                <Map
                    height="100%"
                    width="100%"
                    defaultState={{ center: [61.249201, 73.376575], zoom: 13 }} >
                    <Placemark geometry={[61.249201, 73.376575]} />
                </Map>
            </YMaps>
        </div>
    )
}
export default YNMap