import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 100px)',
    background: '#ffff',
  },
}))

function VK49865Page() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <h1>ВК49865</h1>
    </div>
  )
}

export default VK49865Page
